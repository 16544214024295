import React from "react";
import styled from "styled-components";

import { useStoreActions, useStoreState } from "../store/rootStore";
import { DamageNumberType } from "../types/Damage";
import { Building } from "../types/Report";
import Headline from "./Headline";
import HouseIcon from "./HouseIcon";
import { Row, Half, Column } from "./Layout";
import Text from "./Text";
import UsageImage from "./UsageImage";

/**
 * @param building
 * @param damages
 * @returns {JSX.Element}
 * @constructor
 */
function BuildingOverview({
  building,
  damages,
}: {
  building: Building;
  damages: DamageNumberType;
}) {
  const { openDamageTypes } = useStoreState((state) => state.Report);
  const { setDamageTypes } = useStoreActions((actions) => actions.Report);
  let exception = false;
  if (building.Exception) {
    if (building.Exception.length > 0) {
      if (
        // @ts-ignore
        building.Exception[0].Unavailable ||
        // @ts-ignore
        building.Exception[0].PartlyUnavailable ||
        // @ts-ignore
        building.Exception[0].Comments
      ) {
        exception = true;
      }
    }
  }
  return (
    <Container>
      <HeaderContainer>
        <UsageYear>
          <UsageImage usage={building.Usage} />
          <Year>{building.ConstructionYear}</Year>
        </UsageYear>
        <Headline top={16} h2>
          Litra {building.Litra}
        </Headline>
      </HeaderContainer>
      <Row left={24} right={4} bottom={4}>
        <Half>
          <RowOrColumn>
            <Key>Etager</Key>
            <Value>{building.Floors}</Value>
          </RowOrColumn>
          <RowOrColumn>
            <Key>Anvendelse</Key>
                      <Value>{building.Name ?? building.Usage}</Value>
          </RowOrColumn>
          <RowOrColumn>
            <Key>Opført</Key>
            <Value>{building.ConstructionYear}</Value>
          </RowOrColumn>
        </Half>
        <Half>
          <RowOrColumn justify="flex-end">
            <Key mobileEnd>Bolig M2</Key>
            <ValueRight>{building.InhabitedArea}</ValueRight>
          </RowOrColumn>
          <RowOrColumn justify="flex-end">
            <Key mobileEnd>Bebygget M2</Key>
            <ValueRight>{building.TotalEstablishedArea}</ValueRight>
          </RowOrColumn>
          <RowOrColumn justify="flex-end">
            <Key mobileEnd>Kælder M2</Key>
            <ValueRight>{building.BasementArea}</ValueRight>
          </RowOrColumn>
        </Half>
      </Row>
      {!exception && (
        <Damages onClick={() => setDamageTypes(!openDamageTypes)}>
          <Column>
            <HouseIcon icon="Red" />
            <Number>{damages.Red}</Number>
          </Column>
          <Column>
            <HouseIcon icon="Yellow" />
            <Number>{damages.Yellow}</Number>
          </Column>
          <Column>
            <HouseIcon icon="Grey" />
            <Number>{damages.Grey}</Number>
          </Column>
          <Column>
            <HouseIcon icon="Un" />
            <Number>{damages.Un}</Number>
          </Column>
        </Damages>
      )}
      {exception && (
        <Exception>Bygning medtages ikke i tilstandsrapporten</Exception>
      )}
    </Container>
  );
}

export const RowOrColumn = styled.div<{
  justify?: string;
  top?: string;
  bottom?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(p) => (p.justify ? p.justify : "flex-start")};
  margin-top: ${(p) => (p.top ? `${p.top}px` : "0")};
  margin-bottom: ${(p) => (p.bottom ? `${p.bottom}px` : "10px")};
  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

const Number = styled.div`
  color: ${(p) => p.theme.damangeAmount};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
`;

const Exception = styled.div`
  font-size: 20px;
  color: red;
  transform: rotate(-15deg);
  text-align: center;
  position: absolute;
  bottom: 120px;
  left: 40px;
`;

const Damages = styled.div`
  position: absolute;
  bottom: 0;
  left: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 96px);
  cursor: pointer;
  @media (max-width: 1024px) {
    position: relative;
    padding-top: 8px;
  }
`;

const Year = styled(Text)<{ mobileEnd?: boolean }>`
  color: #757575;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 4px;
  margin-right: 2px;
  display: flex;
  @media (max-width: 420px) {
    justify-content: ${(p) => (p.mobileEnd ? "flex-end" : "flex-start")};
  }
`;

const Key = styled(Text)<{ mobileEnd?: boolean }>`
  color: ${(p) => p.theme.cellKeycolor};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-top: 4px;
  margin-right: 20px;
  width: ${(p) => (p.mobileEnd ? "98px" : "88px")};
  display: flex;
  @media (max-width: 420px) {
    justify-content: ${(p) => (p.mobileEnd ? "flex-end" : "flex-start")};
  }
`;

const Value = styled(Text)`
  color: ${(p) => p.theme.cellValueColor};
  margin-top: 4px;
  font-weight: bold;
  min-width: 38px;
  margin-right: 20px;
`;

const ValueRight = styled(Value)`
  text-align: right;
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.litraCardBg};
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
`;

const UsageYear = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default BuildingOverview;

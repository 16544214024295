import React from "react";
import styled from "styled-components";

import HouseIcon from "./HouseIcon";
import { Column, Row } from "./Layout";
import Text from "./Text";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function DamageTypes() {
  return (
    <Column>
      <Row bottom={24}>
        <House>
          <HouseIcon icon="Red" />
        </House>
        <Column>
          <Header>Kritiske skader</Header>
          <Text size={14}>
            Skader ved bygningsdelen, som allerede har medført eller vil
            medføre, at bygningsdelens funktioner svigter på{" "}
            <Bold>kort sigt</Bold>. Dette svigt kan medføre eller kan allerede
            have medført skader på andre bygningsdele.
          </Text>
        </Column>
      </Row>
      <Row bottom={24}>
        <House>
          <HouseIcon icon="Yellow" />
        </House>
        <Column>
          <Header>Alvorlige skader</Header>
          <Text size={14}>
            Skader ved bygningsdele, som vil medføre, at bygningsdeles funktion
            svigter på <Bold>længere sigt</Bold>. Dette svigt kan medføre skader
            på andre bygningsdele.
          </Text>
        </Column>
      </Row>
      <Row bottom={24}>
        <House>
          <HouseIcon icon="Grey" />
        </House>
        <Column>
          <Header>Mindre alvorlige skader</Header>
          <Text size={14}>
            Skader, som ikke har nogen indflydelse på bygningsdelens eller
            bygningens funktion.
          </Text>
        </Column>
      </Row>
      <Row bottom={24}>
        <House>
          <HouseIcon icon="Un" />
        </House>
        <Column>
          <Header>Mulige skader</Header>
          <Text size={14}>
            Forhold ved bygningsdelen, som bør undersøges for at afklare, om det
            er en skade. Det kan være en kritisk skade eller en alvorlig skade.
            Derfor bør du afklare, den mulige skades omfang og konsekvenser.
            Hvis du vælger at købe ejendommen, uden af forholdet er undersøgt
            nærmere, bærer du selv risikoen.
          </Text>
        </Column>
      </Row>
      <div>
        <Text size={14}>
          <Bold>Kort sigt</Bold> er, når den bygningssagkyndige ud fra sin
          indsigt og erfaring, skønner, at skaden udvikler sig negativt indenfor
          en periode på op til circa 2 år. Det kan være meget individuelt,
          hvordan en skade udvikler sig. Det kan være væsenligt hurtigere end 2
          år. Det er også muligt, at den negative udvikling allerede er begyndt.
        </Text>
      </div>
    </Column>
  );
}

const House = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 4px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

export default DamageTypes;

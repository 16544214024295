import { action, ActionOn, actionOn } from "easy-peasy";

import {
  BrandFeatures,
  defaultFeatureList,
  DefaultFeatures,
  FeatureListType,
} from "../features";
import Requester from "../helpers/Requester";
import { Report } from "../types/Report";
// eslint-disable-next-line import/no-cycle

type FeatureStateType = {
  requester?: Requester;
  isDebug: boolean;
  report?: Report;
};

const defaultState: FeatureStateType = {
  requester: undefined,
  isDebug: process.env.NODE_ENV === "development",
  report: undefined,
};

type State = FeatureListType & FeatureStateType;

function getFeatureList(state: State, report: Report): FeatureListType {
  if (state.requester?.isEdc()) {
    return BrandFeatures.EdcFeatures(report);
  }

  if (state.requester?.isEstate()) return BrandFeatures.EstateFeatures(report);

  if (state.requester?.isJesperNielsen())
    return BrandFeatures.JesperNielsenFeatures(report);

  if (state.requester?.isNybolig())
    return BrandFeatures.NyboligFeatures(report);

  return BrandFeatures.EdcFeatures(report);
}

export function mergeFeatureLists(
  defaults: FeatureListType,
  overrides: FeatureListType,
): FeatureListType {
  if (!defaults) {
    return overrides;
  }

  if (!overrides) {
    return defaults;
  }

  const merged = {
    ...defaults,
  };

  Object.keys(defaults).forEach((featureKey) => {
    if (featureKey in overrides) {
      // @ts-ignore
      merged[featureKey] = {
        // @ts-ignore
        ...defaults[featureKey],
        // @ts-ignore
        ...overrides[featureKey],
      };
    }
  });
  return merged;
}

export function forEachFeature(callbackFn: (params: { key: string }) => void) {
  Object.keys(defaultFeatureList)
    // @ts-ignore
    .map((key: keyof typeof defaultFeatureList) => {
      return {
        key,
        feature: defaultFeatureList[key],
      };
    })
    .forEach(({ key }) => callbackFn({ key }));
}

export type FeatureSetUrlTypes = "klimaberegner" | "theme2022";
export const featureSetUrls = [
  "klimaberegner",
  "theme2022",
] as Array<FeatureSetUrlTypes>;

const setStateFeatures = (state: State, features: FeatureListType) =>
  forEachFeature(({ key }) => {
    // @ts-ignore
    if (features[key]) {
      // @ts-ignore
      state[key] = features[key];
    }
  });

const computedState = {
  proxyReport: actionOn(
    () => "@action.Report.setReport",
    (state, target) => {
      const report: Report = target.payload;
      state.report = report;
      state.requester = new Requester(report.RequestorName);

      const features = mergeFeatureLists(
        DefaultFeatures(report),
        getFeatureList(state, report),
      );
      setStateFeatures(state, features);
    },
  ) as ActionOn<State>,
};

const actions = {
  setFeatureList: action<State, string>((state, payload) => {
    const features = mergeFeatureLists(
      // @ts-ignore
      DefaultFeatures(state.report),
      // @ts-ignore
      BrandFeatures[payload](state.report),
    );

    setStateFeatures(state, features);
  }),
  enableFeatures: action<State, { key: string; isEnabled: boolean }>(
    (state, payload) => {
      // @ts-ignore
      state[payload.key].isEnabled = payload.isEnabled;
    },
  ),
};

const featureStore = {
  ...defaultFeatureList,
  ...defaultState,
  ...computedState,
  ...actions,
};

export default featureStore;

import React from "react";
import styled from "styled-components";

function Headline(props: any) {
  const { h1, h2, h3, h4, h5, bottom, top, side, ...rest } = props;
  const headlineProps = { b: bottom, t: top, s: side, ...rest };
  if (h1) {
    return <Head1 {...headlineProps} />;
  }
  if (h2) {
    return <Head2 {...headlineProps} />;
  }
  if (h3) {
    return <Head3 {...headlineProps} />;
  }
  if (h4) {
    return <Head4 {...headlineProps} />;
  }
  if (h5) {
    return <Head5 {...headlineProps} />;
  }
  return null;
}

const withMargin = (Element: any) => styled(Element)`
  margin-top: ${(p) => (p.t ? `${p.t}px` : "0")};
  margin-bottom: ${(p) => (p.b ? `${p.b}px` : "0")};
  margin-left: ${(p) => (p.s ? `${p.s}px` : "0")};
  margin-right: ${(p) => (p.s ? `${p.s}px` : "0")};
`;

const withTextColor = (Element: any) => styled(Element)`
  color: #090c33;
`;

const Head1 = withMargin(
  withTextColor(styled.h1`
    font-size: 40px;
    font-family: Helvetica;
    font-weight: bold;
    text-transform: uppercase;
    @media (max-width: 1024px) {
      text-align: center;
      font-size: 30px;
    }
  `),
);

const Head2 = withMargin(
  withTextColor(styled.h2`
    font-size: 30px;
    font-family: Helvetica;
    font-weight: bold;
    margin: 0;
    @media (max-width: 1024px) {
      text-align: center;
      font-size: 24px;
    }
  `),
);

const Head3 = withMargin(
  withTextColor(styled.h3`
    font-size: 24px;
    font-family: Helvetica;
    font-weight: bold;
    margin: 0;
  `),
);

const Head4 = withMargin(
  withTextColor(styled.h4`
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 0.5ch;
  `),
);

const Head5 = withMargin(
  withTextColor(styled.h5`
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  `),
);

export default Headline;

import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createStore, StoreProvider } from "easy-peasy";
import React from "react";

import { FeatureToolbar } from "./components/FeatureToolbar";
import Router from "./Router";
import model from "./store/rootStore";
import theme from "./styles/muitheme";

const store = createStore(model);

// Fix for: https://github.com/ctrlplusb/easy-peasy/issues/741
type Props = StoreProvider["props"] & { children: React.ReactNode };
const StoreProviderCasted =
  StoreProvider as unknown as React.ComponentType<Props>;

function App() {
  return (
    <StoreProviderCasted store={store}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <FeatureToolbar>
          <Router />
        </FeatureToolbar>
      </ThemeProvider>
    </StoreProviderCasted>
  );
}

export default App;

import React from "react";

function VimeoIframe({
  width,
  height,
  ratio,
  videoid,
}: {
  width: number;
  height?: number;
  ratio?: "16:9" | "4:3";
  videoid: string;
}) {
  let newRatio = ratio;
  let newWidth = width;
  let newHeight = height;

  if (!newRatio) {
    newRatio = "16:9";
  }
  if (!!newWidth && !!newHeight) {
    newWidth = 200;
  }
  if (!!newWidth || !!newHeight) {
    const [ratioWidthStr, ratioHeightStr] = newRatio.split(":");
    const [ratioWidth, ratioHeight] = [
      parseFloat(ratioWidthStr),
      parseFloat(ratioHeightStr),
    ];

    if (!newHeight) {
      newHeight = (newWidth / ratioWidth) * ratioHeight;
    } else {
      newWidth = (newHeight / ratioHeight) * ratioWidth;
    }
  }
  const borderStyle = {
    border: "Solid",
    borderColor: "#d9d9d9",
    borderWidth: "2px",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <iframe
        title="NRGi Video"
        src={`https://player.vimeo.com/video/${videoid}`}
        width={newWidth - 20}
        height={newHeight}
        style={borderStyle}
        allow="autoplay;
        fullscreen;
        picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

export default VimeoIframe;

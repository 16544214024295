import React from "react";
import styled from "styled-components";

function Headline(props: any) {
  const { children, h1, h2, h3, h4, h5, bottom, top, side } = props;
  if (h1) {
    return (
      <Head1 b={bottom} t={top} s={side}>
        {children}
      </Head1>
    );
  }
  if (h2) {
    return (
      <Head2 b={bottom} t={top} s={side}>
        {children}
      </Head2>
    );
  }
  if (h3) {
    return (
      <Head3 b={bottom} t={top} s={side}>
        {children}
      </Head3>
    );
  }
  if (h4) {
    return (
      <Head4 b={bottom} t={top} s={side}>
        {children}
      </Head4>
    );
  }
  if (h5) {
    return (
      <Head5 b={bottom} t={top} s={side}>
        {children}
      </Head5>
    );
  }
  return null;
}

const Head1 = styled.h1<{ t?: string; b?: string; s?: string }>`
  color: ${(p) => p.theme.headlineColor};
  font-size: ${(p) => p.theme.headerSize || "40px"};
  font-family: ${(p) => p.theme.headerFontFamily};
  font-weight: bold;
  text-transform: uppercase;
  margin-top: ${(p) => (p.t ? `${p.t}px` : "0")};
  margin-bottom: ${(p) => (p.b ? `${p.b}px` : "0")};
  margin-left: ${(p) => (p.s ? `${p.s}px` : "0")};
  margin-right: ${(p) => (p.s ? `${p.s}px` : "0")};
  @media (max-width: 1024px) {
    text-align: center;
    font-size: 30px;
  }
`;

const Head2 = styled.h2<{ t?: string; b?: string; s?: string }>`
  color: ${(p) => p.theme.headlineColor};
  font-size: 30px;
  font-family: ${(p) => p.theme.headerFontFamily};
  font-weight: bold;
  margin: 0;
  margin-top: ${(p) => (p.t ? `${p.t}px` : "0")};
  margin-bottom: ${(p) => (p.b ? `${p.b}px` : "0")};
  margin-left: ${(p) => (p.s ? `${p.s}px` : "0")};
  margin-right: ${(p) => (p.s ? `${p.s}px` : "0")};
  @media (max-width: 1024px) {
    text-align: center;
    font-size: 24px;
  }
`;

const Head3 = styled.h3<{ t?: string; b?: string; s?: string }>`
  color: ${(p) => p.theme.headlineColor};
  font-size: 24px;
  font-family: ${(p) => p.theme.headerFontFamily};
  font-weight: bold;
  margin: 0;
  margin-top: ${(p) => (p.t ? `${p.t}px` : "0")};
  margin-bottom: ${(p) => (p.b ? `${p.b}px` : "0")};
  margin-left: ${(p) => (p.s ? `${p.s}px` : "0")};
  margin-right: ${(p) => (p.s ? `${p.s}px` : "0")};
`;

const Head4 = styled.h4<{ t?: string; b?: string; s?: string }>`
  color: ${(p) => p.theme.headlineColor};
  font-family: ${(p) => p.theme.textFontFamily};
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-top: ${(p) => (p.t ? `${p.t}px` : "0")};
  margin-bottom: ${(p) => (p.b ? `${p.b}px` : "0")};
  margin-left: ${(p) => (p.s ? `${p.s}px` : "0")};
  margin-right: ${(p) => (p.s ? `${p.s}px` : "0")};
`;

const Head5 = styled.h5<{ t?: string; b?: string; s?: string }>`
  color: ${(p) => p.theme.headlineColor};
  font-family: ${(p) => p.theme.textFontFamily};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  margin-top: ${(p) => (p.t ? `${p.t}px` : "0")};
  margin-bottom: ${(p) => (p.b ? `${p.b}px` : "0")};
  margin-left: ${(p) => (p.s ? `${p.s}px` : "0")};
  margin-right: ${(p) => (p.s ? `${p.s}px` : "0")};
`;

export default Headline;

import { create } from "apisauce";

import { BuildingExpert, Files, Report } from "./types/Report";
import { VersionData } from "./types/Version";

export const baseUrl = () => {
  const isWindow = typeof window !== "undefined";
  let Url = "http://ebas-web-thf01v.nrgi.local/";

  if (isWindow && process.env.NODE_ENV !== "development") {
    Url = `${window.location.protocol}//${window.location.host}/`;
  }
  return Url;
};

const api = create({
  baseURL: baseUrl(),
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
  },
  timeout: 10000,
});

export const getReport = (id: string) =>
  api.get<Report>(`reports/${id.substring(0, 1)}/${id}.json`);

export const getFilelist = (id: string) =>
  api.get<Files>(`reportFiles/${id.substring(0, 1)}/${id}.json`);

export const dictionary = () =>
  api.get<{ [key: string]: string }>("dictlqxm101/ccmlpwoghww59f.json");

export const backendVersion = () => api.get<VersionData>(`backendversion`);

export const getBuildingExpert = (processId: string) =>
  api.get<BuildingExpert>(`api/BuildingExpert/${processId}`);

export const getEnergyLabel = (id: string) =>
  api.get<string>(`api/vtr/energymarkSerial/${id}`);

export const getRedirect = (id:string) =>
    api.get<string>(`api/vtr/redirectto/${id}`);

import { Dialog } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  open: boolean;
  close: () => void;
  title?: string;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
};

function Popup({ open, close, title, children, maxWidth }: Props) {
  return (
    <Dialog
      maxWidth={maxWidth || false}
      onClose={() => close()}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {title && <Header>{title}</Header>}
      <Text>{children}</Text>
      {title && (
        <Footer>
          <Ok onClick={() => close()}>ok</Ok>
        </Footer>
      )}
    </Dialog>
  );
}

const Header = styled.div`
  height: 30px;
  background-color: #f2f2f2;
  padding: 15px;
  display: flex;
  color: #275954;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
`;

const Footer = styled(Header)`
  justify-content: flex-end;
`;

const Ok = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  height: 30px;
  width: 80px;
  text-transform: uppercase;
  color: #001a50;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 14px;
`;

const Text = styled.div`
  padding: 20px;
`;

export default Popup;

import { createTypedHooks } from "easy-peasy";

import featureStore from "./featureStore";
import homeStore from "./homeStore";
import reportStore from "./reportStore";

const stores = {
  Features: featureStore,
  Home: homeStore,
  Report: reportStore,
};

const typedHooks = createTypedHooks<typeof stores>();

export const { useStoreActions, useStoreDispatch, useStoreState, useStore } =
  typedHooks;

export default stores;

import { CircularProgress } from "@material-ui/core";
import React, { useEffect, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { damageNumbers } from "../../helpers/calc";
import initGA from "../../helpers/ga";
import sortLitra from "../../helpers/sort";
import { useStoreActions, useStoreState } from "../../store/rootStore";
import { Gjensidige } from "../../styles";
import { BuildingExpert } from "../../types/Report";
import BigImages from "../BigImages";
import Popup from "../Popup";
import Damages from "./Damages";

const {
  DamageTypes,
  BuildingOverview,
  CaseOverview,
  Reservations,
  Header,
  Spacing,
  Footer,
  Intro,
  Container,
  Overview,
} = Gjensidige;

function GjensidigeReport() {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error("No ID was found!");
  }

  const ref = useRef(null);
  const {
    report,
    files,
    loaded,
    openDamageTypes,
    selectedDamage,
    dict,
    dictOpenWord,
  } = useStoreState((state) => state.Report);

  const { getReport, setDamageTypes, setSelectedDamage, setDictOpenWord } =
    useStoreActions((actions) => actions.Report);

  useEffect(() => {
    if (!loaded && !report) {
      getReport(id);
    }
  }, [report, loaded]);

  useEffect(() => {
    if (loaded) {
      initGA(report);
    }
  }, [report, loaded]);

  if (!report) {
    return <p>Report not found</p>;
  }

  if (!files) {
    return <p>Files not found!</p>;
  }
  const buildingExpert = report.Consultant as BuildingExpert;
  return (
    <Page ref={ref}>
      <Container>
        {!loaded && <CircularProgress />}
        {loaded && (
          <>
            <Header
              address={`${report.Estate.Address}, ${report.Estate.ZipCode} ${report.Estate.City}`}
              // id={id}
            />
            <MiddleCon>
              <Main className="main">
                <Intro />
                <Overview
                  fadein
                  id={id}
                  photo={files.casePhoto}
                  litraPhotos={files.litra}
                >
                  <CaseOverview
                    buildings={report.Buildings}
                    date={report.InspectionDate}
                    buildingExpert={buildingExpert}
                  />
                </Overview>
                <Reservations />
                {report.Buildings.length > 0 && (
                  <PadBottom>
                    {report.Buildings.sort(sortLitra).map((b) => (
                      <Fragment key={b.Litra}>
                        <Overview id={id} photo={files.litra[b.Litra]}>
                          <BuildingOverview
                            damages={damageNumbers(
                              report.Damages.filter(
                                (d) => d.BuildingLitra === b.Litra,
                              ),
                            )}
                            building={b}
                          />
                        </Overview>
                        <Spacing height={32} />
                        <Damages
                          elements={b.Elements}
                          floors={b.Floor}
                          id={id}
                          images={files.damages}
                          damages={report.Damages}
                          litra={b.Litra}
                        />
                      </Fragment>
                    ))}
                  </PadBottom>
                )}
                <Footer
                  ignoreList={["Edc"]}
                  InspectionDate={report.InspectionDate}
                  RequesterName={report.RequestorName}
                  company={report.company ? report.company : ""}
                />
              </Main>
            </MiddleCon>

            <Popup
              title={dictOpenWord ?? ""}
              maxWidth="sm"
              open={dictOpenWord !== null}
              close={() => {
                setDictOpenWord(null);
              }}
            >
              {/* @ts-ignore */}
              {dict[dictOpenWord]}
            </Popup>
            <Popup
              maxWidth="sm"
              title="Oversigt over skadestyper"
              open={openDamageTypes}
              close={() => setDamageTypes(false)}
            >
              <DamageTypes />
            </Popup>
            <Popup
              maxWidth="md"
              open={selectedDamage !== -1}
              close={() => setSelectedDamage(-1)}
            >
              <BigImages id={id} />
            </Popup>
          </>
        )}
      </Container>
    </Page>
  );
}

const Page = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100%;
`;

const MiddleCon = styled.div`
  width: 1024px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const PadBottom = styled.div`
  padding-bottom: 3ch;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    margin-top: 0;
    padding-top: 2ch;
    margin-bottom: 0;
  }

  > * + * {
    padding-top: 1.5rem;
    //padding-top: 52ch, 1.5rem;
  }
`;

export default GjensidigeReport;

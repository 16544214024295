import React, { useState } from "react";
import styled from "styled-components";

import { calcPan } from "../helpers/calc";
import { useStoreState } from "../store/rootStore";
import { Shared } from "../styles";
import DamageImage from "./DamageImage";

const { Column, Row, Image } = Shared;

function BigImages({ id }: { id: string }) {
  const { images } = useStoreState((state) => state.Report);
  const [selected, setSelected] = useState("");

  return (
    <Column>
      <ImgContainer>
        <DamageImage
          width={600}
          selected={selected}
          resetSelected={() => setSelected("")}
          images={images}
          id={id}
        />
      </ImgContainer>
      <ImagesCon>
        {images.map((i) => (
          <ImageCon onClick={() => setSelected(i.fileId)} key={i.fileId}>
            <Image
              scale={i.zoom}
              x={calcPan("x", i.pan, 80, i.zoom)}
              y={calcPan("y", i.pan, 80, i.zoom)}
              src={i.fileId}
              id={id}
              small
            />
          </ImageCon>
        ))}
      </ImagesCon>
    </Column>
  );
}

const ImgContainer = styled.div`
  width: 600px;
  height: 600px;
`;

const ImagesCon = styled(Row)`
  margin-top: 16px;
`;

const ImageCon = styled.div`
  margin-right: 8px;
  height: 80px;
  width: 80px;
  overflow: hidden;
  cursor: pointer;
`;

export default BigImages;

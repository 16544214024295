import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import CookiePolitikPage from "./pages/Politik/CookiePolitik";
import PersondataPolitikPage from "./pages/Politik/PersondataPolitik";
import HomePage from "./pages/Report";
import EjerskiftePage from "./pages/Report/Ejerskifte";
import ElrapportPage from "./pages/Report/Elrapporten";
import EnergiberegnerPage from "./pages/Report/Energiberegner";
import TilstandsrapportPage from "./pages/Report/Tilstandsrapport";
import SplashPage from "./pages/Splash";
import { featureSetUrls } from "./store/featureStore";
import { useStoreState } from "./store/rootStore";
import themes from "./styles/themes";
// import { featureSetUrls } from "./features/featureStore";
//
// function Root(props: any) {
//   return <div {...props} />;
// }

function Router() {
  const features = useStoreState((x) => x.Features);
  const { landingPage, theme } = features;

  return (
    <ThemeProvider
      theme={theme.isEnabled ? themes[theme.value!] : themes.default}
    >
      <Routes>
        <Route path="/" element={<SplashPage />} />

        {[...featureSetUrls, "/"].map((x) => (
          <Route key={x} path={x}>
            <Route path=":id">
              {landingPage.isEnabled ? (
                <>
                  <Route index element={<HomePage />} />
                  <Route
                    path="tilstandsrapport"
                    element={<TilstandsrapportPage />}
                  />
                  <Route
                    path="energiberegner"
                    element={<EnergiberegnerPage />}
                  />
                  <Route path="elrapporten" element={<ElrapportPage />} />
                  <Route path="ejerskifte" element={<EjerskiftePage />} />
                </>
              ) : (
                <Route index element={<TilstandsrapportPage />} />
              )}

              <Route path="cookiepolitik" element={<CookiePolitikPage />} />
              <Route
                path="persondatapolitik"
                element={<PersondataPolitikPage />}
              />
              <Route path="*" element={<TilstandsrapportPage />} />
            </Route>
          </Route>
        ))}
      </Routes>
    </ThemeProvider>
  );
}

export default Router;

import React, { PropsWithChildren } from "react";
import styled, { StyledComponent } from "styled-components";

import { useStoreActions, useStoreState } from "../store/rootStore";
import Anchor from "./Anchor";

export function dictionaryTextDecorator<P, Props extends PropsWithChildren<P>>(
  TextElement: React.FC<Props> | StyledComponent<any, any>,
  dict: { [key: string]: string },
): React.FC<Props> {
  return function (props) {
    const { setDictOpenWord } = useStoreActions((x) => x.Report);
    const Elem = dictionaryTextDetector(
      TextElement,
      {
        ...dict,
      },
      setDictOpenWord,
    );
    return (
      <div>
        <Elem {...props} />
      </div>
    );
  };
}

function dictionaryTextDetector<P, Props extends PropsWithChildren<P>>(
  TextElement: React.FC<Props> | StyledComponent<any, any>,
  dict: { [key: string]: string },
  setOpenWord: any,
): React.FC<Props> {
  function dictionaryContains(word: string) {
    const dictKeys = Object.keys(dict);
    const [hit] = dictKeys.filter(
      (dictWord) => dictWord.toLowerCase() === word.toLowerCase(),
    );
    return !!hit;
  }

  function replaceWord({
    word,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    setOpenWord,
  }: {
    word: string;
    setOpenWord: Function;
  }) {
    const dictKeys = Object.keys(dict);
    const [hit] = dictKeys.filter(
      (dictWord) => dictWord.toLowerCase() === word.toLowerCase(),
    );

    return (
      <UnderlinedTextElement
        onClick={() => {
          setOpenWord(hit);
        }}
        href="#"
      >
        {word}
      </UnderlinedTextElement>
    );
  }

  return function ({ children, ...props }) {
    const ReplaceWord = replaceWord;

    // @ts-ignore
    const flattenedChildren = [].concat(...[children]);

    const dictionaryReplacedWords = flattenedChildren.map((x: any, index) => {
      if (!!x && typeof x === "string") {
        return x.split(" ").reduce((elements: any[], word, i, arr) => {
          const isLast = i === arr.length - 1;

          return [
            ...elements,
            dictionaryContains(word) ? (
              isLast ? (
                <ReplaceWord
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  word={word}
                  setOpenWord={setOpenWord}
                />
              ) : (
                [
                  <ReplaceWord
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    word={word}
                    setOpenWord={setOpenWord}
                  />,
                  " ",
                ]
              )
            ) : (
              `${word}${!isLast ? " " : ""}`
            ),
          ];
        }, []);
      }
      return x;
    });

    const joinedProps = {
      ...props,
      // eslint-disable-next-line react/jsx-no-useless-fragment
      children: <>{dictionaryReplacedWords}</>,
    } as Props;
    return <TextElement {...joinedProps} />;
  };
}

export default (TextElement: any) => {
  return function (props: any) {
    const { dict } = useStoreState((state) => state.Report);
    const Elem = dictionaryTextDecorator(TextElement, {
      ...dict,
    });
    return <Elem {...props} />;
  };
};

const UnderlinedTextElement = styled(Anchor)`
  text-decoration: underline;
  color: #0000ee;
`;

import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

function PersondataPolitikPage() {
  useEffect(() => {
    window.open(
      "https://nrgi.dk/privat/vilkaar-data-og-privatliv/persondatapolitik-for-nrgi-raadgivning/",
      "_blank",
    );
  }, []);

  const location = useLocation();

  const paths = location.pathname.split("/");
  paths.pop();

  return <Navigate to={`${paths.join("/")}/tilstandsrapport`} />;

  //
  // return (
  //   <Page>
  //     <Container>
  //       <Box sx={{ padding: { xs: "10px 20px 10px", md: "30px 0 70px" } }}>
  //         <h1>Persondatapolitik</h1>
  //         <Typography>
  //           Formålet med vores persondatapolitik er at forklare, hvilke
  //           personoplysninger vi indsamler, hvorfor vi indsamler dem, samt dine
  //           rettigheder i den forbindelse.
  //         </Typography>
  //         <h3>Dataansvarlig</h3>
  //         <Typography>
  //           NRGi Rådgivning er dataansvarlig for behandlingen af de
  //           personoplysninger, som vi indsamler om dig via vores hjemmeside. Du
  //           er altid velkommen til at kontakte os, hvis du har spørgsmål til
  //           denne persondatapolitik og vores behandling af dine
  //           personoplysninger. Vores kontaktoplysninger er:
  //           <br />
  //           <br />
  //           NRGi Rådgivning <br />
  //           CVR-nr. 33077831 <br />
  //           Lautrupvang 2, 2750 Ballerup <br />
  //           Tlf.: 70 12 56 56 <br />
  //           E-mail: <a href="mailto:info@nrgi.dk">info@nrgi.dk</a>
  //         </Typography>
  //         <h5>Indsamling af personoplysninger</h5>
  //         <Typography>
  //           De personoplysninger (f.eks. navn og adresse), vi indsamler på
  //           hjemmesiden, er de personoplysninger, som du giver os, når du
  //           klikker dig ind på vores hjemmeside eller på anden vis udleverer ved
  //           besøget på vores hjemmeside.
  //           <br />
  //           <br />
  //           Vi benytter såkaldte cookies til at indsamle oplysninger af teknisk
  //           karakter samt til statistiske formål. Læs vores{" "}
  //           <Link to={`${paths.join("/")}/cookiepolitik`}>cookiepolitik</Link>.
  //         </Typography>
  //         <h5>
  //           Formål og retsgrundlag for behandling af dine personoplysninger
  //         </h5>
  //         <Typography>
  //           Vi indsamler kun de personoplysninger, som er nødvendige for at vi
  //           kan levere den pågældende ydelse til dig, og vi anvender kun dine
  //           personoplysninger til de formål, der er beskrevet i denne
  //           persondatapolitik.
  //           <br />
  //           <br />
  //           Vi opbevarer kun de registrerede personoplysninger så længe, det er
  //           nødvendigt for at kunne opfylde de formål, til hvilke dine
  //           personoplysninger er indsamlet, eller så længe vi er
  //           lovgivningsmæssigt forpligtet hertil, jf. nærmere nedenfor.
  //           <br />
  //           <br />
  //           Når du klikker dig ind på vores hjemmeside logges din IP-adresse til
  //           statistiske og tekniske formål. Dette sker med grundlag i
  //           Persondataforordningens art. 6, stk. 1, f), jf.
  //           Databeskyttelseslovens § 6 stk. 1.
  //           <br />
  //           <br />
  //           Herudover afgiver du selv visse personoplysninger til os via
  //           elektroniske formularer eller lignende på hjemmesiden
  //         </Typography>
  //         <h5>Kontaktformularer</h5>
  //         <Typography>
  //           Hvis du bruger kontaktformularer på hjemmesiden, vil vi have brug
  //           for dit navn, din e-mail adresse, dit tlf.nr., og hvilken ejendom,
  //           din henvendelse drejer sig om. Oplysningerne er nødvendige for at
  //           kunne besvare din henvendelse, jf. Persondataforordningens art. 6,
  //           stk. 1, f), jf. Databeskyttelseslovens § 6 stk. 1. Oplyser du andre
  //           personoplysninger i kontaktformularen, vil vi behandle disse
  //           oplysninger hvor nødvendigt til brug for besvarelse af din
  //           henvendelse.
  //           <br />
  //           <br />
  //           Din henvendelse og dine oplysninger vil blive slettet efter 3
  //           måneder, med mindre du er blevet kunde hos os, og vi derfor gemmer
  //           dine oplysninger for at opfylde kundeaftalen med dig indtil 3 år
  //           efter kundeforholdets ophør eller 5 år efter kundeforholdets ophør,
  //           hvis der er tale om bogføringsmateriale.
  //         </Typography>
  //         <h3>Datasikkerhed</h3>
  //         <Typography>
  //           Vi prioriterer datasikkerhed meget højt og har derfor stor fokus på
  //           at bidrage til, at vores behandling af dine personoplysninger
  //           overholder den til enhver tid gældende persondatalovgivning. Vi har
  //           implementeret såvel tekniske som organisatoriske
  //           sikkerhedsforanstaltninger, der har til formål at beskytte dine
  //           oplysninger mod hændelig eller ulovlig tilintetgørelse, at de
  //           fortabes eller forringes, samt mod at de kommer til uvedkommendes
  //           kendskab, misbruges eller i øvrigt behandles i strid med
  //           persondatalovgivningen.
  //         </Typography>
  //         <h5>Behandling hos tredjepart</h5>
  //         <Typography>
  //           Vi benytter databehandlere, herunder udbydere af software, til bl.a.
  //           backup, sikkerhed og opbevaring. Dine personoplysninger vil i så
  //           fald blive overladt til tredjeparter, instrueret af os til at
  //           behandle dine personoplysninger på vores vegne. Databehandlerne
  //           behandler således ikke dine personoplysninger til andre formål end
  //           til opfyldelse af deres aftale med os.
  //           <br />
  //           <br />
  //           Vi forpligter databehandlerne til at behandle dine personoplysninger
  //           fortroligt, og til at træffe de fornødne tekniske og organisatoriske
  //           sikkerhedsforanstaltninger mod at oplysninger hændeligt eller
  //           ulovligt tilintetgøres, fortabes eller forringes, eller kommer til
  //           uvedkommendes kendskab, misbruges eller i øvrigt behandles i strid
  //           med persondataforordningen.
  //         </Typography>
  //         <h5>Videregivelse af personoplysninger</h5>
  //         <Typography>
  //           Vi videregiver ikke dine oplysninger til tredjemand uden først at få
  //           dit udtrykkelige samtykke, med mindre vi er lovgivningsmæssigt
  //           forpligtet hertil.
  //           <br />
  //           <br />
  //           Vi forbeholder os dog retten til at videregive og benytte dine
  //           personoplysninger i andre selskaber i NRG-koncernen uden dit
  //           udtrykkelige samtykke, dog kun såfremt videregivelsen er nødvendig
  //           for at opfylde det oprindelige formål med vores indsamling af dine
  //           personoplysninger.
  //         </Typography>
  //         <h5>Dine rettigheder</h5>
  //         <Typography>
  //           Vi passer godt på dine personoplysninger og behandler dem i
  //           overensstemmelse med Persondataforordningen. Vi respekterer dine
  //           rettigheder i henhold til Persondataforordningen, og du vil altid
  //           have ret til følgende, jf. Persondataforordningens art. 15-22:
  //           <br />
  //           <br />
  //           <ul>
  //             <li>
  //               at trække dit samtykke til behandling af dine personoplysninger
  //               tilbage, hvis behandlingen er baseret på dit samtykke. Evt.
  //               tilbagetrækning af dit samtykke berører ikke lovligheden af den
  //               behandling, der har fundet sted, inden samtykket tilbagetrækkes
  //             </li>
  //             <li>
  //               at bede om indsigt i dine personoplysninger, som vi har
  //               indsamlet samt at få nærmere information om bl.a. formål,
  //               klagemuligheder mv
  //             </li>
  //             <li>
  //               at få berigtiget evt. urigtige personoplysninger om dig selv i
  //               særlige tilfælde at få dine personoplysninger slettet i visse
  //               tilfælde at få behandlingen af dine personoplysninger begrænset.
  //             </li>
  //             <li>
  //               i visse tilfælde at gøre indsigelse mod vores ellers lovlige
  //               behandling af dine personoplysninger
  //             </li>
  //             <li>
  //               i visse tilfælde at modtage dine personoplysninger i et
  //               struktureret, almindeligt anvendt og maskinlæsbart format samt
  //               at få overført disse personoplysninger til en anden
  //               dataansvarlig
  //             </li>
  //             <li>
  //               at indgive klage til Datatilsynet over vores behandling af dine
  //               personoplysninger. Du finder Datatilsynets kontaktoplysninger på{" "}
  //               <a href="https://www.datatilsynet.dk/">www.datatilsynet.dk</a>.{" "}
  //             </li>
  //           </ul>
  //           <br />
  //           <br />
  //           Alle ovenstående rettigheder håndteres manuelt ved henvendelse til
  //           os på <a href="mailto:persondata@nrgi.dk">persondata@nrgi.dk</a>.
  //           <br />
  //           <br />
  //           Du kan læse mere om dine rettigheder i Datatilsynets vejledning om
  //           de registreredes rettigheder, som du finder på{" "}
  //           <a href="https://www.datatilsynet.dk/">www.datatilsynet.dk</a>.
  //           <br />
  //           <br />
  //           Version 1 10.01.2023
  //         </Typography>
  //       </Box>
  //     </Container>
  //   </Page>
  // );
}

// const Page = styled.div`
//   height: 100vh;
//   overflow-y: auto;
//   width: 100%;
// `;
export default PersondataPolitikPage;

import { Button, Grid, Dialog, Typography, Box } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { ElMistakeCaseType } from "../../types/ElMistake";
import CardContainer from "../Gjensidige/CardContainer";
import { errorType } from "./ElMistakeList";

type Props = {
  mistakeCase: ElMistakeCaseType;
};

function ElMistakeCard({ mistakeCase }: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <CardContainer style={{ maxWidth: "90%" }} cardHeight="500px">
      <div
        style={{
          display: "flex",
          flex: 0,
          justifyContent: "space-between ",
          flexDirection: "column",
        }}
      >
        <div>
          <div>
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={50}
              pagination={{ clickable: true }}
              navigation
            >
              {mistakeCase.mistakes
                .flatMap((mistake) => mistake.images)
                .map((image, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img src={image} width={331} alt="El Mistake" />
                  </SwiperSlide>
                ))}
              <Box
                style={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  zIndex: 99,
                }}
              >
                {mistakeCase.errorTypes
                  ?.sort((a, b) => a - b)
                  .map((errorTypeKey, index) => {
                    const errorTypeValue = errorType[errorTypeKey];
                    return (
                      <img
                        key={index}
                        src={errorTypeValue.image}
                        width={25}
                        style={{ marginRight: 10 }}
                        alt="error type symbol"
                      />
                    );
                  })}
              </Box>
            </Swiper>
          </div>
          <div style={{ padding: "15px" }}>
            <Typography
              variant="h6"
              align="left"
              style={{ minHeight: 70, lineHeight: "25px" }}
              gutterBottom
            >
              {mistakeCase.header}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              gutterBottom
              style={{ padding: "15px 0px" }}
            >
              {mistakeCase.subtitle}
            </Typography>
          </div>
          <Box
            style={{
              position: "absolute",
              bottom: "30px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              style={{
                borderRadius: "50px",
                width: "70%",
                padding: "5px 0px",
              }}
              onClick={() => setModalOpen(true)}
            >
              LÆS MERE OM FEJLEN
            </Button>
          </Box>
        </div>
        <CardDialog
          mistakeCase={mistakeCase}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </div>
    </CardContainer>
  );
}

type CardDialogProps = {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  mistakeCase: ElMistakeCaseType;
};

function CardDialog({ modalOpen, setModalOpen, mistakeCase }: CardDialogProps) {
  return (
    <Dialog
      open={modalOpen}
      style={{
        height: "100%",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
      onClose={() => setModalOpen(false)}
    >
      <div
        style={{
          backgroundColor: "#fff",
          // width: "620px",
          padding: "10px",
          textAlign: "center",
          overflow: "hidden",
          overflowY: "scroll",
          maxHeight: "80%",
        }}
      >
        <h2 id="simple-modal-title">{mistakeCase.header}</h2>
        <Grid container justifyContent="space-evenly">
          {mistakeCase.mistakes.map((mistake, index) => (
            <Grid
              key={index}
              item
              xs={12}
              // style={{ textAlign: "start", padding: "40px" }}
            >
              {/* <div
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                      height: '200px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <img src={desc.image} style={{}} />
                  </div> */}

              {mistake.images && (
                <Swiper
                  modules={[Navigation, Pagination]}
                  // spaceBetween={50}
                  style={{ width: "100%" }}
                  pagination={{ clickable: true }}
                  navigation
                >
                  {mistake.images.map((image, i) => (
                    <SwiperSlide
                      key={i}
                      style={{ textAlign: "center", width: "100%!important" }}
                    >
                      <img
                        src={image}
                        alt="El Mistake"
                        width="100%!important"
                      />
                    </SwiperSlide>
                  ))}
                  <Box
                    style={{
                      position: "absolute",
                      bottom: 10,
                      right: 10,
                      zIndex: 99,
                    }}
                  >
                    {mistakeCase.errorTypes
                      ?.sort((a, b) => a - b)
                      .map((errorTypeKey, errorIndex) => {
                        const errorTypeValue = errorType[errorTypeKey];
                        return (
                          <img
                            key={errorIndex}
                            src={errorTypeValue.image}
                            width={25}
                            style={{ marginRight: 10 }}
                            alt="error type symbol"
                          />
                        );
                      })}
                  </Box>
                </Swiper>
              )}

              <Typography
                style={{
                  backgroundColor: "rgb(247 225 115 / 87%)",
                  padding: "5px 0",
                  fontSize: "14px",
                  fontWeight: "bold",
                  margin: "10px 0",
                }}
              >
                Bemærk! Fotos er eksempler og er ikke taget i den specifikke
                bolig
              </Typography>
              {mistake.descriptions.map((desc, i) => (
                <Fragment key={i}>
                  <Grid container direction="row" style={{ textAlign: "left" }}>
                    <Grid item xs={12}>
                      <Typography variant="h6" style={{ textAlign: "left" }}>
                        {desc.question}
                      </Typography>
                      <p
                        style={{ marginTop: 0 }}
                        dangerouslySetInnerHTML={{
                          __html: desc.answer,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          ))}
        </Grid>

        {mistakeCase.errorTypeComments?.map((comment) => (
          <Typography
            style={{
              backgroundColor: "lightgray",
              padding: 20,
              fontSize: 13,
              fontWeight: "bold",
            }}
          >
            {comment}
          </Typography>
        ))}
        <Button
          style={{ marginTop: "20px" }}
          variant="outlined"
          size="large"
          onClick={() => setModalOpen(false)}
        >
          Luk
        </Button>
      </div>
    </Dialog>
  );
}

export default ElMistakeCard;

import React, { useRef, useEffect, useState, Fragment } from "react";
import styled from "styled-components";

import { spacifySlash } from "../../helpers/text";
import { useStoreActions, useStoreState } from "../../store/rootStore";
import { Gjensidige } from "../../styles";
import DictionaryTextDecorator from "../../styles/DictionaryTextDecorator";
import {
  Damage,
  DamageImage as DamageImageType,
  Floor,
} from "../../types/Report";
import Popup from "../Popup";
import CardContainer from "./CardContainer";
import DamageImage from "./DamageImage";

const { Text, Line, Headline, HouseIcon } = Gjensidige;

type Props = {
  floors: Floor[];
  damage: Damage;
  elements: any[];
  images: DamageImageType[];
  id: string;
};

function DamageCard({ floors, elements, damage, images, id }: Props) {
  const { openDamageTypes } = useStoreState((state) => state.Report);
  const { setDamageTypes, setSelectedDamage } = useStoreActions(
    (actions) => actions.Report,
  );
  const [cardWith, setCardWidth] = useState(331);
  const [openPlacement, setOpenPlacement] = useState(false);
  const ref = useRef<{ offsetWidth: number }>(null);

  useEffect(() => {
    setCardWidth(ref.current ? ref.current.offsetWidth : 331);
  }, []);

  let headerText = "Skade:";
  if (damage.BuildingPart || damage.DamagedPart) {
    headerText = `${damage.BuildingPart} (${damage.DamagedPart})`;
  }

  let count = 0;
  if (damage.SelectedElements) {
    if (damage.SelectedElements.length > 0) {
      count = damage.SelectedElements.length;
    }
  }
  if (damage.SelectedRooms) {
    if (damage.SelectedRooms.length > 0) {
      count = damage.SelectedRooms.length;
    }
  }

  const getElement = (elementId: string) => {
    if (elements) {
      const element = elements.filter((e) => e.Id === elementId);
      if (element.length > 0) {
        return element[0].Name;
      }
    }
    return "";
  };

  let rooms: Floor[] = [];
  if (floors) {
    rooms = floors.map((a) => {
      if (a.Rooms && damage.SelectedRooms) {
        return {
          ...a,
          Rooms: a.Rooms.filter((e) => damage.SelectedRooms.indexOf(e.Id) > -1),
        };
      }
      return { ...a, Rooms: [] };
    });
  }

  const selectDamage = () => {
    // @ts-ignore
    if (window !== "undefined") {
      if (window.innerWidth > 1024) {
        setSelectedDamage(damage.LocationNumber);
      }
    }
  };

  return (
    <>
      <CardContainer ref={ref}>
        <ImgContainer onClick={() => selectDamage()} width={cardWith}>
          <DamageImage small width={cardWith} images={images} id={id} />
        </ImgContainer>
        <TextContainer cardWidth={cardWith}>
          <Headline h5>{spacifySlash(headerText)}</Headline>
          <DictionaryText size={14}>{damage.Comment}</DictionaryText>
          <Placement onClick={() => setOpenPlacement(!openPlacement)} size={14}>
            Se Placering ({count})
          </Placement>
          <Line top={4} bottom={4} />
          <Key size={14}>Risiko:</Key>
          {damage.Risk && (
            <DictionaryText size={14}>{damage.Risk}</DictionaryText>
          )}
          {damage.Risks && (
            <DictionaryText size={14}>{damage.Risks}</DictionaryText>
          )}
          <Line top={4} bottom={4} />
          <Key size={14}>Bemærkning:</Key>
          <DictionaryText size={14}>{damage.Note}</DictionaryText>
        </TextContainer>
        <IconContainer
          onClick={() => setDamageTypes(!openDamageTypes)}
          cardWidth={cardWith}
        >
          <HouseIcon bg icon={damage.Grade} />
        </IconContainer>
      </CardContainer>
      <Popup
        title="Skaden forefindes her:"
        open={openPlacement}
        close={() => setOpenPlacement(false)}
      >
        {damage.SelectedElements && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {damage.SelectedElements.length > 0 && (
              <>
                <PopupHeader>Udvendigt:</PopupHeader>
                {damage.SelectedElements.map((e: any) => (
                  <Text key={e} size={14}>
                    {getElement(e)}
                  </Text>
                ))}
              </>
            )}
          </>
        )}
        {damage.SelectedRooms && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {damage.SelectedRooms.length > 0 && (
              <>
                <PopupHeader>Indvendigt:</PopupHeader>
                {rooms.map((floor) => (
                  <Fragment key={floor.Name}>
                    {floor.Rooms.length > 0 && (
                      <>
                        <Text size={14} bold>
                          Etage: {floor.Name}
                        </Text>
                        {floor.Rooms.map((r) => (
                          <Text size={14} key={r.Name}>
                            {r.Name}
                          </Text>
                        ))}
                      </>
                    )}
                  </Fragment>
                ))}
              </>
            )}
          </>
        )}
      </Popup>
    </>
  );
}

const DictionaryText = DictionaryTextDecorator(Text);

const PopupHeader = styled.div`
  color: #275954;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Key = styled(Text)`
  font-weight: bold;
`;

const Placement = styled(Text)`
  color: #0000ee;
  cursor: pointer;
  font-weight: bold;
`;

const IconContainer = styled.div<{ cardWidth: number }>`
  position: absolute;
  right: 16px;
  top: ${(p) => p.cardWidth - 54}px;
  cursor: pointer;
  @media (max-width: 1024px) {
    top: ${(p) => p.cardWidth - 49}px;
  }
`;

const TextContainer = styled.div<{ cardWidth: number }>`
  padding: 8px;
  padding-bottom: 0;
  height: ${(p) => 569 - p.cardWidth}px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 1024px) {
    height: auto;
    margin-bottom: 4px;
  }
`;

const ImgContainer = styled.div<{ width: number }>`
  height: ${(p) => `${p.width}px`};
  width: 100%;
  cursor: pointer;
`;

export default DamageCard;

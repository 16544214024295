import { Grid } from "@material-ui/core";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import * as React from "react";
import { Link, matchRoutes, useLocation } from "react-router-dom";
import styled from "styled-components";

import Headline from "./Headline";
import Text from "./Text";

const pageWidth = "100%";
const maxContentWidth = "1024px";
const minPadding = "24px";

const paddingForWidth = `max(calc(min(${pageWidth}, calc(${pageWidth} - ${maxContentWidth}))/ 2), ${minPadding})`;
const outerMarign = `min(16ch, ${paddingForWidth})`;

const UsePaddingForWidth = {
  paddingLeft: paddingForWidth,
  paddingRight: paddingForWidth,
};

const Routes = [
  {
    route: "tilstandsrapport",
    title: "Visuel tilstandsrapport",
    linkText: "Visuel tilstandsrapport",
  },
  {
    route: "energiberegner",
    title: undefined,
    linkText: "Energiberegner",
  },
  {
    route: "elrapporten",
    title: "Om elrapporten",
    linkText: "Elrapport",
  },
  {
    route: "ejerskifte",
    title: "Om ejerskifteforsikringen",
    linkText: "Gjensidige ejerskifteforsikring",
  },
] as Array<{ route: string; title?: string; linkText: string }>;

function NavigationLink({
  children,
  path,
}: {
  children: React.ReactNode;
  path: string;
}) {
  const location = useLocation();
  // const { id } = useParams();

  try {
    const uri = new URL(path);
    if (!!uri && uri.origin !== window.location.origin) {
      return (
        <HeaderAnchor href={path} target="_blank" rel="noreferrer noopener">
          {children}
        </HeaderAnchor>
      );
    }
  } catch {
    // pass
  }

  // const match = useMatch(path);
  const paths = location.pathname.split("/");
  const currentLocation = paths.pop();
  // const match = location.pathname === path;
  if (currentLocation === path) {
    return <WhiteText bold>{children}</WhiteText>;
  }

  return (
    // <HeaderLink as={Link} to={generatePath(path, { id })}>
    <HeaderLink as={Link} to={`${paths.join("/")}/${path}`}>
      {children}
    </HeaderLink>
  );
}

const GetHeaderText = () => {
  const location = useLocation();
  const [match] = Routes.filter((x) =>
    matchRoutes([{ path: x.route }], location),
  ).map((r) => r.title);
  return match;
};

function Header({ address }: { address: string }) {
  const NavigationLinks = Routes.map((route) => {
    return (
      <NavigationLink key={route.route} path={route.route}>
        {route.linkText}
      </NavigationLink>
    );
  });

  const headerText = GetHeaderText();

  const minHeight = window.outerWidth < 800 ? "11ch" : "6ch";
  return (
    <Container>
      <Grid container direction="column">
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{
            minHeight,
            backgroundColor: "#090C33",
            paddingLeft: outerMarign,
            paddingRight: outerMarign,
          }}
        >
          <Grid item>
            <HeaderLink as={Link} to="../">
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Arrow />
                </Grid>
                <Grid item>
                  <WhiteText>{address}</WhiteText>
                </Grid>
              </Grid>
            </HeaderLink>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center" spacing={3}>
              {NavigationLinks.map((link, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={index.toString()} item>
                  {link}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          style={{
            backgroundColor: "#ffffff",
            ...UsePaddingForWidth,
          }}
        >
          {!!headerText && headerText.trim().length > 0 ? (
            <>
              <Headline h1 top="24" bottom="24">
                {headerText}
              </Headline>
              <Line />
            </>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
}

const WhiteText = styled(Text)`
  color: white;
`;

const HeaderAnchor = styled.a`
  color: white;
  text-decoration: none;
`;

const HeaderLink = styled(WhiteText)`
  text-decoration: none;
`;

const Container = styled.div`
  position: sticky;
  top: 0;
  width: ${pageWidth};
  z-index: 133700;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #cccccc;
  @media (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const Arrow = styled(KeyboardArrowLeftRounded)`
  color: white;
  margin-top: 5px;
`;

export default Header;

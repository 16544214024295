import styled from "styled-components";

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Text = styled.div<{
  size?: number;
  right?: boolean;
  pre?: boolean;
  bold?: boolean;
}>`
  color: #275954;
  font-size: ${(p) => (p.size ? `${p.size}px` : "15px")};
  text-align: ${(p) => (p.right ? "right" : "left")};
  white-space: ${(p) => (p.pre ? "pre-wrap" : "normal")};
  font-weight: ${(p) => (p.bold ? "bold" : "normal")};
`;
export default Text;

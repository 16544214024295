/* eslint-disable prettier/prettier */
import React, {useState} from 'react';
import styled from 'styled-components';

import {baseUrl} from "../api";

const url = (src: string, id: string) => `${baseUrl()}reportPhotos/${id.substring(0, 1)}/${id}/${src}.jpg`;
const urlSmall = (src: string, id: string) => `${baseUrl()}reportPhotos/${id.substring(0, 1)}/${id}/small/${src}.jpg`;

function Image(props: { src?: any, id?: any, small?: any, filter?: any, scale?: any, x?: any, y?: any, rotate?: any, building?: any }) {
    const {src, id, small, filter, scale, x, y, rotate, building} = props;
    const [vertical, setVertical] = useState(false);

    const onLoad = (e: any) => {
        if (e.target.naturalWidth > e.target.naturalHeight) {
            setVertical(true);
        }
    };

    if (small) {
        return (
            <Img onLoad={onLoad} building={building} vertical={vertical} scale={scale} x={x} y={y} rotate={rotate}
                 filter={filter} src={urlSmall(src, id)}/>
        );
    }
    return <Img onLoad={onLoad} building={building} vertical={vertical} scale={scale} x={x} y={y} rotate={rotate}
                filter={filter} src={url(src, id)}/>;
}

const Img = styled.img<{ vertical?: boolean, scale?: string, filter?: string, x?: string, y?: string; rotate?: string, building?: any }>`
  ${p => (p.scale ? '' : 'object-fit: cover;')};
  width: ${p => (p.vertical ? 'auto' : '100%')};
  height: ${p => (p.scale && !p.vertical ? 'auto' : '100%')};
  filter: ${p => (p.filter ? p.filter : 'none')};
  transform: scale(${p => (p.scale ? p.scale : '1')}) translate(${p => p.x}px, ${p => p.y}px) rotate(${p => p.rotate}deg);
  ${p => p.building && '@media (max-width: 1024px) { width: 100%; height: auto; }'}
`;

export default Image;

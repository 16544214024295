import { ThemeType } from "../../types/Theme";
import defaultTheme from "./default";

const jesperNielsenTheme: ThemeType = {
  ...defaultTheme,
  headerCellColor: "#1B1B1A",
  headerCellBg: "#FFFFFF",
  cellColor: "#FFFFFF",
  cellBg: "#231F20",
  damageAmount: "#231F20",
  cellValueColor: "#231F20",
  cellKeyColor: "#231F20",
  ringColor: "#231F20",
  placementLinkColor: "#231F20",
  anchorColor: "#231F20",
  headerSize: "32px",
  litraCardBg: "#f8f5f0",
  dmgCardBg: "#f8f5f0",
  caseOverviewCardBg: "#f8f5f0",
};

export default jesperNielsenTheme;

import BuildingOverview from "./BuildingOverview";
import BuildingsList from "./BuildingsList";
import CaseOverview from "./CaseOverview";
import Circle from "./Circle";
import Container from "./Container";
import DamageTypes from "./DamageTypes";
import Footer from "./Footer";
import * as GjensidigeImport from "./Gjensidige";
import Header from "./Header";
import Headline from "./Headline";
import HouseIcon from "./HouseIcon";
import Image from "./Image";
import Intro from "./Intro";
import { Column, Half, Line, Row, Spacing } from "./Layout";
import Link from "./Link";
import theme from "./muitheme";
import { Overview } from "./Overview";
import Text from "./Text";
import UsageImage from "./UsageImage";

const Shared = {
  Container,
  Footer,
  Headline,
  HouseIcon,
  Image,
  Row,
  Half,
  Column,
  Line,
  Spacing,
  Link,
  theme,
  Overview,
  UsageImage,
};

const SharedGjensidige = {
  ...Shared,
  ...GjensidigeImport,
};

const SharedGeneric = {
  BuildingOverview,
  BuildingsList,
  CaseOverview,
  Circle,
  DamageTypes,
  Header,
  Intro,
  Text,
  ...Shared,
};

export const Gjensidige = SharedGjensidige;
export { Shared };
export const generic = SharedGeneric;

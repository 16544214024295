import {
  ElMistakeCaseType,
  ErrorType,
  ErrorTypeSymbol,
} from "../../types/ElMistake";

const mistakeImage = (imageName: string) => `/images/el-mistake/${imageName}`;

export const mistakes: ElMistakeCaseType[] = [
  {
    header: "Stikkontakter mangler",
    subtitle:
      "Der skal være én stikkontakt pr. fire kvadratmeter i et rum. Det er et krav...",
    errorTypes: [ErrorTypeSymbol.ILLEGAL, ErrorTypeSymbol.FIRE],
    errorTypeComments: [
      `Hvis der mangler én stikkontakt vil denne type fejl få mærket "Ulovlig installation". Hvis der mangler to eller flere stikkontakter, vil den blive mærket med "Risiko for brand"`,
    ],
    mistakes: [
      {
        images: [mistakeImage("Fejl1-stikkontakter.png")],
        descriptions: [
          {
            question: "Hvad betyder det?",
            answer:
              "Der skal være én stikkontakt pr. fire kvadratmeter i et rum. Det er et krav, der er stillet af Sikkerhedsstyrelsen. Derfor kan et eller flere af rummene have for få stikkontakter, hvis antallet ikke opfylder lovkravet.",
          },
          {
            question: "Hvorfor kan det være farligt?",
            answer:
              "Er der for få stikkontakter i et rum, er det sandsynligt, at man vil bruge forlængerledninger for at få flere stik. Dermed opstår der en øget risiko for overbelastning i de elektriske installationer og en øget brandfare, da kablerne bliver for varme. For at undgå den situation, har Sikkerhedsstyrelsen besluttet, at alle rum skal have det antal stikkontakter, som en gennemsnitlig boligejer har brug for.",
          },
          {
            question: "Hvad kan du gøre ved det?",
            answer:
              "En elektriker kan installere flere stikkontakter, så rummet opfylder lovkravet om en stikkontakt pr. fire kvadratmeter.",
          },
        ],
      },
    ],
  },
  {
    header: "Forkert brug af tilledning og forkert brug af ledning",
    subtitle:
      "Denne fejl opstår, hvis der fx er sat flere forlængerledninger sammen, eller...",
    errorTypes: [ErrorTypeSymbol.FIRE],
    mistakes: [
      {
        images: [
          mistakeImage("Fejl 2- billede 1 Forkert brug af tilledning.png"),
          mistakeImage("Fejl 2- billede 2 Forkert brug af tilledning.png"),
        ],
        descriptions: [
          {
            question: "Hvad betyder det?",
            answer:
              "Denne fejl opstår, hvis der fx er sat flere forlængerledninger sammen, eller hvis forlængerledningerne er skruet fast til væggen. Så bliver det en farlig eller unødvendig brug af forlængerledninger, og det er ulovligt. Forlængerledninger må heller ikke bruges til faste installationer. Faste installationer er noget, du har stående, som vejer over 15 kg, det kan fx være en stor espressomaskine. Faste installationer må ikke være forbundet med en forlængerledning, men skal sidde direkte i en stikkontakt.",
          },
          {
            question: "Hvorfor kan det være farligt?",
            answer:
              "Forkert brug af forlængerledninger kan give øget belastning i de enkelte stikkontakter og øge brandfaren, da ledningerne kan blive for varme.",
          },
          {
            question: "Hvad kan du gøre ved det?",
            answer:
              "Sæt dine faste installationer direkte i en stikkontakt i stedet for at bruge en forlængerledning. Har du ikke stikkontakter nok, eller er de ikke placeret, hvor du har brug for dem, kan du få en elektriker til at installere nogle flere.",
          },
        ],
      },
    ],
  },
  {
    header: "Spots: Forkert brug af ledningstyper",
    subtitle:
      "Spots i loftet kan være ulovligt installeret. Og det er vigtigt, at de spots, der er brugt...",
    errorTypes: [ErrorTypeSymbol.FIRE],
    mistakes: [
      {
        images: [
          mistakeImage("Fejl 3 - billede 1 Forkert ledningstype spot.png"),
          mistakeImage("Fejl 3 - billede 2 Forkert ledningstype spot.png"),
        ],
        descriptions: [
          {
            question: "Hvad betyder det?",
            answer:
              "Spots i loftet kan være ulovligt installeret. Og det er vigtigt, at de spots, der er brugt, er godkendt til det materiale, de skal sidde i. Skal spottene fx sidde direkte i isoleringen i loftet, skal de være godkendt til det. Er de ikke godkendt til at sidde direkte i isoleringen, kan man installere en kasse, som afskærmer spottet fra isoleringen – en såkaldt safebox.",
          },
          {
            question: "Hvorfor kan det være farligt?",
            answer:
              "Spots kan blive meget varme. Hvis dit spot ikke er beregnet til at sidde i isolering, er der en stor brandfare, da varmen fra spottet kan få isoleringen til at bryde i brand.",
          },
          {
            question: "Hvad kan du gøre ved det?",
            answer:
              "Du har to muligheder: 1. Skift dine spots til en type, der er godkendt til at sidde i det loft, du har 2. Installer dine nuværende spots på en sikker måde. Det kan fx være ved at sætte en såkaldt safebox på, der sikrer, at varmen fra spottet ikke bevæger sig ud i isoleringen eller andre brandfarlige materialer.",
          },
        ],
      },
    ],
  },
  {
    header: "For stor åbning i eltavle",
    subtitle:
      "Der kan være åbninger i din eltavle, som ikke skal være der. Det kan enten være huller...",
    errorTypes: [ErrorTypeSymbol.SHOCK],
    mistakes: [
      {
        images: [mistakeImage("Fejl 4 - billede 1 For stor aabning tavle.png")],
        descriptions: [
          {
            question: "Hvad betyder det?",
            answer:
              "Der kan være åbninger i din eltavle, som ikke skal være der. Det kan enten være huller, der sidder foran, hvor du kan se dem, men det kan også være huller, der sidder i tavlens bagplade, topplade eller bundplade, hvor du ikke umiddelbart kan se dem. For store huller i eltavlen vil ofte sidde der, hvor kablerne kommer ind i tavlen. Der må ikke være huller i din eltavle – uanset hvor de sidder.",
          },
          {
            question: "Hvorfor kan det være farligt?",
            answer:
              "Hvis der mangler afdækning på forsiden af tavlen, risikerer du at få stød, og det samme kan ske, hvis du får fingre, værktøj eller andet ind i hullerne på bagpladen, toppladen eller bundpladen.",
          },
          {
            question: "Hvad kan du gøre ved det?",
            answer:
              "Hullerne i tavlen skal dækkes. Hvis der mangler afdækning på forsiden, skal den sættes på, og andre huller skal lukkes af en elektriker.",
          },
        ],
      },
    ],
  },
  {
    header: "Manglende beskyttelsesleder/Forkert brug af beskyttelsesleder",
    subtitle: "",
    errorTypes: [ErrorTypeSymbol.SHOCK],
    mistakes: [
      {
        images: [mistakeImage("Fejl 5 - manglende beskyttelsesleder.png")],
        descriptions: [
          {
            question: "Hvad betyder det?",
            answer:
              "Typisk ses fejlen ved hvidevarer, hvor stikket mangler jordforbindelse – også kaldet beskyttelsesleder. Beskyttelseslederen er det tredje ben i stikket, der fx kan være på dit køleskab, vaskemaskine eller tørretumbler. Det tredje ben er det, der giver jordforbindelsen. Nye hvidevarer leveres med et schukostik, som har et ”omvendt jordben”, dvs. et hul ind i stedet for et ben ud (se billede nedenfor). For at få jordforbindelse kræver det, at stikkontakten eller stikdåsen er forsynet med selve benet, og det er det ofte ikke i Danmark. Hvis du sætter et schukostik i en stikkontakt som type K, du kan se nedenfor, vil det ikke have jordforbindelse.",
          },
          {
            question: "Hvorfor kan det være farligt?",
            answer:
              "Du risikerer stød, når dine hårde hvidevarer ikke har  jordforbindelse.",
          },
          {
            question: "Hvad kan du gøre ved det?",
            answer:
              "Du kan købe en adapter til schukostikket, der kan sikre jordforbindelsen.",
          },
        ],
      },
    ],
  },
  {
    header: "Eltavlen mangler opmærkning om tilhørsforhold",
    subtitle: "",
    errorTypes: [ErrorTypeSymbol.ILLEGAL],
    mistakes: [
      {
        images: [mistakeImage("Fejl 6 - billede 1 manglende opmærkning.png")],
        descriptions: [
          {
            question: "Hvad betyder det?",
            answer:
              "Der skal stå på din eltavle, hvilke områder i boligen de enkelte grupper kører til, fx ”lys i køkken” eller ”vaskemaskine”.",
          },
          {
            question: "Hvorfor kan det være farligt?",
            answer:
              "Der er ingen direkte fare forbundet med manglende opmærkning, men det er en klar fordel for dig at vide, hvilke grupper der går til hvad, hvis du skal have slået strømmen fra til et bestemt apparat eller område i en fart.",
          },
          {
            question: "Hvad kan du gøre ved det?",
            answer: "Sæt labels med navne på grupperne på eltavlen.",
          },
        ],
      },
    ],
  },
  {
    header: "Der er kun en RCD, men der skal være mindst to i eltavlen",
    subtitle: "",
    errorTypes: [ErrorTypeSymbol.ILLEGAL],
    mistakes: [
      {
        images: [mistakeImage("Fejl 7 - billede 1 RCD.png")],
        descriptions: [
          {
            question: "Hvad betyder det?",
            answer:
              "En RCD, Residual Current Device, er en fejlstrømsafbryder. Den slår strømmen fra ved kortslutning og beskytter dermed dig og din bolig. Efter 1. juli 2019 skal installationer som udgangspunkt være fordelt på mindst to RCD’er af typen A 30 mA. Hvis din installation er ældre, vil det ikke fremgå af rapporten som en fejl.",
          },
          {
            question: "Hvorfor kan det være farligt?",
            answer:
              "Der skal være 2 RCD’er for at sikre, at strømmen ikke går i hele boligen, hvis der sker en fejl i en enkelt gruppe.",
          },
          {
            question: "Hvad kan du gøre ved det?",
            answer:
              "Få en elektriker til at installere en ekstra RCD type A 30 mA",
          },
        ],
      },
    ],
  },
  {
    header:
      "Der mangler en eller flere sikringsgrupper til belysning og stikkontakter",
    subtitle: "",
    errorTypes: [ErrorTypeSymbol.FIRE],
    mistakes: [
      {
        images: [mistakeImage("Fejl 8- billede 1 sikringsgrupper.png")],
        descriptions: [
          {
            question: "Hvad betyder det?",
            answer: `Der er forskellige regler for, hvor mange sikringsgrupper der skal være i et hus. Det er husets byggeår – eller ombygningsår, der afgør, hvor mange sikringsgrupper der skal være. Hvis der står i elrapporten, at der mangler sikringsgrupper, skyldes det, at antallet af sikringsgrupper ikke lever op til de krav, der var, da huset blev bygget, renoveret eller bygget om.
På sikkerhedsstyrelsens hjemmeside er kravene forklaret. <a href="https://www.sik.dk/erhverv/elinstallationer-og-elanlaeg/vejledninger/elinstallationer/boliger/kravene-beskyttelsesudstyr-og-antallet-grupper#:~:text=Installationsbekendtg%C3%B8relsen-,Beregning%20af%20antallet%20af%20grupper,dog%20v%C3%A6re%20mindst%202%20grupper" target="_blank">Læs mere</a>`,
          },
          {
            question: "Hvorfor kan det være farligt?",
            answer: `Hvis der er for få sikringsgrupper, kan det skabe overbelastning af en enkelt gruppe og øge brandfaren.
              Vi bruger mere og mere elektronik i vores boliger. Derfor skal de opdeles af lysgrupper for at undgå overbelastning`,
          },
          {
            question: "Hvad kan du gøre ved det?",
            answer: "Få en elektriker til at installere flere lysgrupper.",
          },
        ],
      },
    ],
  },
  {
    header:
      "Der er flere steder anvendt ulovlige, bøjelige ledninger som fast installation",
    subtitle: "",
    errorTypes: [ErrorTypeSymbol.FIRE],
    mistakes: [
      {
        images: [mistakeImage("fejl 9-bøjelige-ledninger.png")],
        descriptions: [
          {
            question: "Hvad betyder det?",
            answer: `
Bøjelige ledninger må ikke bruges til faste installationer. En bøjelige ledning kan fx være en forlængerledning. Faste installationer er noget, du har stående, som vejer over 15 kg.
En bøjelige ledning med et ledertværsnit på under 1,5 mm² kaldes en tilledning. Tilledninger må ikke bruges eller være anbragt på måder, som gør dem særligt udsatte for skader, fx på gulv, under møbler eller gennem dør- og vinduesåbninger. Er to tilledninger samlet, skal de være samlet i en dåse med låg.  Samlinger må ikke være udført med fritsiddende muffer. 
Tilledninger skal starte og slutte i samme rum, og de må ikke være ført gennem faste bygningsdele fx lofter og vægge. 
`,
          },
          {
            question: "Hvorfor kan det være farligt?",
            answer:
              "Forkert brug af bøjelige ledninger kan give øget belastning i de enkelte stikkontakter og øge brandfaren, da ledningerne kan blive for varme.",
          },
          {
            question: "Hvad kan du gøre ved det?",
            answer:
              "Sæt dine faste installationer direkte i en stikkontakt i stedet for at bruge til- eller forlængerledninger. Har du ikke stikkontakter nok, eller er de ikke placeret, hvor du har brug for dem, kan du få en elektriker til at installere nogle flere.",
          },
        ],
      },
    ],
  },
];

export const errorType: Record<ErrorTypeSymbol, ErrorType> = {
  [ErrorTypeSymbol.FIRE]: {
    title: "Risiko for brand",
    description: "Ulovlige elinstallationer, der indebærer risiko for brand.",
    image: "/images/symbols/risiko-for-brand.png",
  },
  [ErrorTypeSymbol.SHOCK]: {
    title: "Risiko for stød",
    description:
      "Ulovlige elinstallationer, der indebærer risiko for elektrisk stød.",
    image: "/images/symbols/risiko-for-stoed.png",
  },
  [ErrorTypeSymbol.INVESTIGATE]: {
    title: "Undersøges nærmere",
    description:
      "Forhold ved elinstallationerne, der bør undersøges nærmere for at afklare, om der er en fejl. Det kan være en fejl med risiko for brand eller stød, og derfor bør du undersøge forholdet nærmere. Hvis du vælger at købe ejendommen, uden at forholdet er undersøgt nærmere, bærer du selv risikoen.",
    image: "/images/symbols/undersoeges-naermere.png",
  },
  [ErrorTypeSymbol.ILLEGAL]: {
    title: "Ulovlige elinstallationer",
    description:
      "Elinstallationer, der ikke lever op til de standarder og lovkrav, der gjaldt, da installationen blev udført.",
    image: "/images/symbols/ulovlige-elinstallationer.png",
  },
};

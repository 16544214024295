import React from "react";
import styled from "styled-components";

import Grey from "../images/ic_house_grey.svg";
import Red from "../images/ic_house_red.svg";
import Un from "../images/ic_house_un.svg";
import Yellow from "../images/ic_house_yellow.svg";

const cap = (text: string) => {
  let eng = text;
  if (eng.substring(0, 3).toUpperCase() === "RØD") {
    eng = "red";
  }
  if (eng.substring(0, 3).toUpperCase() === "GUL") {
    eng = "yellow";
  }
  if (eng.substring(0, 3).toUpperCase() === "GRÅ") {
    eng = "grey";
  }
  if (eng.substring(0, 4).toUpperCase() === "SORT") {
    eng = "black";
  }
  return eng.charAt(0).toUpperCase() + eng.slice(1);
};

const images = {
  Grey,
  Red,
  Yellow,
  Un,
  Black: Un,
  K3: Red,
  K2: Yellow,
  K1: Grey,
  K0: Un,
  UN: Un,
};

/**
 * @param icon
 * @param bg
 * @returns {JSX.Element}
 * @constructor
 */
function HouseIcon({ icon, bg }: { icon: string; bg?: boolean }) {
  if (bg) {
    return (
      <Background>
        {/* @ts-ignore */}
        <Img src={images[cap(icon)]} />
      </Background>
    );
  }
  // @ts-ignore
  return <Img src={images[cap(icon)]} />;
}

const Background = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(242, 242, 242, 0.9);
  border: 2px solid #cccccc;
  z-index: 100000;
  position: relative;
`;

const Img = styled.img`
  height: 40px;
  width: 40px;
`;

export default HouseIcon;

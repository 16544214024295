import { CircularProgress } from "@material-ui/core";
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import BigImages from "../../../components/BigImages";
import Damages from "../../../components/Damages";
import GjensidigeReport from "../../../components/Gjensidige";
import Popup from "../../../components/Popup";
import { damageNumbers } from "../../../helpers/calc";
import initGA from "../../../helpers/ga";
import sortLitra from "../../../helpers/sort";
import { useStoreActions, useStoreState } from "../../../store/rootStore";
import { generic } from "../../../styles";

const {
  Container,
  Header,
  Intro,
  Overview,
  CaseOverview,
  BuildingOverview,
  Spacing,
  DamageTypes,
  Footer,
} = generic;

function TilstandsrapportPage(props: any) {
  const [scroll, setScroll] = useState(false);

  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error("No ID was found!");
  }

  const ref = useRef<{ scrollTop: number }>(null);
  const {
    report,
    files,
    loaded,
    openDamageTypes,
    selectedDamage,
    dict,
    dictOpenWord,
  } = useStoreState((state) => state.Report);

  const { theme: styledTheme } = useStoreState((state) => state.Features);

  const { getReport, setDamageTypes, setSelectedDamage, setDictOpenWord } =
    useStoreActions((actions) => actions.Report);

  useEffect(() => {
    if (!report) {
      getReport(id);
    }
  }, [getReport, id, report]);

  useEffect(() => {
    if (loaded) {
      initGA(report);
    }
  }, [report, loaded]);

  const onScroll = () => {
    if (ref.current && ref.current.scrollTop >= 108) {
      setScroll(true);
    } else if (scroll) {
      setScroll(false);
    }
  };

  if (!loaded) {
    return <CircularProgress />;
  }

  if (!report) {
    return <p>Report not found {id}</p>;
  }

  if (!files) {
    return <p>Files not found!</p>;
  }

  if (styledTheme.isEnabled && styledTheme.value === "gjensidige") {
    return <GjensidigeReport {...props} />;
  }

  return (
    // @ts-ignore
    <Page ref={ref} onScroll={() => onScroll()}>
      <Container>
        {!loaded && <CircularProgress />}
        {loaded && (
          <>
            <MiddleCon>
              <Header
                scroll={scroll}
                address={`${report.Estate?.Address}, ${report.Estate?.ZipCode} ${report.Estate?.City}`}
              />
              <Main>
                <Intro type="Introduktion" />
                <Overview
                  fadein
                  id={id}
                  photo={files.casePhoto}
                  litraPhotos={files.litra}
                >
                  <CaseOverview
                    buildings={report.Buildings}
                    date={report.InspectionDate}
                  />
                </Overview>
                <Intro type="Forbehold" />
                {report.Buildings.length > 0 && (
                  <>
                    {report.Buildings.sort(sortLitra).map((b) => (
                      <Fragment key={b.Litra}>
                        <Overview id={id} photo={files.litra[b.Litra]}>
                          <BuildingOverview
                            damages={damageNumbers(
                              report.Damages.filter(
                                (d) => d.BuildingLitra === b.Litra,
                              ),
                            )}
                            building={b}
                          />
                        </Overview>
                        <Spacing height={32} />
                        <Damages
                          elements={b.Elements}
                          floors={b.Floor}
                          id={id}
                          images={files.damages}
                          damages={report.Damages}
                          litra={b.Litra}
                        />
                      </Fragment>
                    ))}
                  </>
                )}
                <Footer
                  InspectionDate={report.InspectionDate}
                  RequesterName={report.RequestorName}
                  company={report.company ? report.company : ""}
                />
              </Main>
            </MiddleCon>
            <Popup
              maxWidth="sm"
              title="Oversigt over skadestyper"
              open={openDamageTypes}
              close={() => setDamageTypes(false)}
            >
              <DamageTypes />
            </Popup>
            <Popup
              maxWidth="md"
              open={selectedDamage !== -1}
              close={() => setSelectedDamage(-1)}
            >
              <BigImages id={id} />
            </Popup>
            <Popup
              title={dictOpenWord ?? ""}
              maxWidth="sm"
              open={dictOpenWord !== null}
              close={() => {
                setDictOpenWord(null);
              }}
            >
              {/* @ts-ignore */}
              {dict[dictOpenWord]}
            </Popup>
          </>
        )}
      </Container>
    </Page>
  );
}

const Page = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100%;
`;

const MiddleCon = styled.div`
  width: 1024px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Main = styled.main`
  padding-top: ${(p) => (p.theme.headerText !== "" ? "115px" : "108px")};
`;
export default TilstandsrapportPage;

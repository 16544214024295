import { Report } from "../../types/Report";
import { disableUntil, FeatureListType, urlFeatureEnabled } from "../index";

const isEnabled = (report: Report): boolean =>
  disableUntil(report, {
    day: 3,
    month: 2,
    year: 2022,
  }) || urlFeatureEnabled("klimaberegner");

export default function EdcFeatures(report: Report): FeatureListType {
  return {
    dictionary: {
      isEnabled: isEnabled(report),
    },
    buildingExpert: {
      isEnabled: isEnabled(report),
    },
    landingPage: {
      isEnabled: isEnabled(report),
    },
    energyCalculator: {
      isEnabled: isEnabled(report),
    },
    introductionVideos: {
      isEnabled: isEnabled(report),
    },
    theme: {
      isEnabled: isEnabled(report),
      value: "gjensidige",
    },
  } as FeatureListType;
}

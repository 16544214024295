import { isBefore, parse } from "date-fns";

import Requester from "./Requester";
import { capitalizeFirstLetter } from "./text";

type RequesterType = {
  RequesterName: string;
  company: string;
  InspectionDate: string;
};

class AvailableCompanies {
  firstMay = new Date(1619827200000);

  requester: Requester;

  company: string;

  isBeforeFirstMay: boolean;

  constructor({ RequesterName, company, InspectionDate }: RequesterType) {
    this.requester = new Requester(RequesterName);
    this.company = company ? capitalizeFirstLetter(company) : "";
    const date = parse(InspectionDate, "dd-MM-yyyy HH:mm:ss", new Date());
    this.isBeforeFirstMay = isBefore(date, this.firstMay);
  }

  isBotjek() {
    return !!this.company && capitalizeFirstLetter(this.company) === "Botjek";
  }

  isEbas() {
    if (this.company) {
      return capitalizeFirstLetter(this.company) === "Ebas";
    }
    return true;
  }

  isDomutech() {
    return this.company.toLowerCase() === "domutech";
  }

  isFactum2() {
    return this.company === "Factum2";
  }

  isObh() {
    return this.company === "Obh";
  }

  isEdc() {
    if (this.company === "Edc") {
      return true;
    }
    return this.requester.isEdc();
  }

  isEstate() {
    if (this.company === "Estate") {
      return true;
    }
    return this.requester.isEstate();
  }

  isNybolig() {
    if (this.company === "Nybolig") {
      return true;
    }
    return this.requester.isNybolig();
  }

  isGjensidige() {
    if (this.company === "Gjensidige") {
      return true;
    }
    if (this.isBeforeFirstMay) {
      if (this.requester.isNybolig()) {
        return true;
      }
      if (this.requester.isEstate()) {
        return true;
      }
    }
    if (this.requester.isEdc()) {
      return true;
    }

    return false;
  }

  isJesperNielsen() {
    return this.requester.isJesperNielsen();
  }
}

export { AvailableCompanies };

/**
 *
 * @param RequestorName
 * @param company
 * @param InspectionDate
 * @returns {AvailableCompanies}
 */
export const getCompaniesForReport = ({
  RequesterName,
  company,
  InspectionDate,
}: RequesterType) => {
  return new AvailableCompanies({ RequesterName, company, InspectionDate });
};

import React from "react";
import styled from "styled-components";

import { useStoreActions, useStoreState } from "../../store/rootStore";
import { DamageNumberType } from "../../types/Damage";
import { Building } from "../../types/Report";
import HouseIcon from "../HouseIcon";
import { Column, Half, Row } from "../Layout";
import UsageImage from "../UsageImage";
import Headline from "./Headline";
import Text from "./Text";

function BuildingOverview({
  building,
  damages,
}: {
  building: Building;
  damages: DamageNumberType;
}) {
  const { openDamageTypes } = useStoreState((state) => state.Report);
  const { setDamageTypes } = useStoreActions((actions) => actions.Report);
  let exception = false;
  if (building.Exception) {
    if (building.Exception.length > 0) {
      if (
        // @ts-ignore
        building.Exception[0].Unavailable ||
        // @ts-ignore
        building.Exception[0].PartlyUnavailable ||
        // @ts-ignore
        building.Exception[0].Comments
      ) {
        exception = true;
      }
    }
  }
  return (
    <Container>
      <HeaderContainer>
        <UsageYear>
          <UsageImage usage={building.Usage} />
        </UsageYear>
        <Headline top={16} h2>
          Litra {building.Litra}
        </Headline>
      </HeaderContainer>
      <Line />
      <Row bottom={4}>
        <Half>
          {building.Floors ? (
            <RowOrColumn>
              <Key>Etager:</Key>
              <Value>{building.Floors}</Value>
            </RowOrColumn>
                  ) : undefined}
                  {building.Name || building.Usage ? (
            <RowOrColumn>
                          <Key>Anvendelse:</Key>
                          <Value>{building.Name ?? building.Usage}</Value>
            </RowOrColumn>
          ) : undefined}
        </Half>
        <Half>
          {building.InhabitedArea ? (
            <RowOrColumn justify="flex-end">
              <Key mobileEnd>Bolig M2:</Key>
              <ValueRight>{building.InhabitedArea}</ValueRight>
            </RowOrColumn>
          ) : undefined}
          {building.TotalEstablishedArea ? (
            <RowOrColumn justify="flex-end">
              <Key mobileEnd>Bebygget M2:</Key>
              <ValueRight>{building.TotalEstablishedArea}</ValueRight>
            </RowOrColumn>
          ) : undefined}
          {building.BasementArea ? (
            <RowOrColumn justify="flex-end">
              <Key mobileEnd>Kælder M2:</Key>
              <ValueRight>{building.BasementArea}</ValueRight>
            </RowOrColumn>
          ) : undefined}
        </Half>
      </Row>
      <Line />
      {!exception && (
        <Damages onClick={() => setDamageTypes(!openDamageTypes)}>
          <Column>
            <HouseIcon icon="Red" />
            <Number>{damages.Red}</Number>
          </Column>
          <Column>
            <HouseIcon icon="Yellow" />
            <Number>{damages.Yellow}</Number>
          </Column>
          <Column>
            <HouseIcon icon="Grey" />
            <Number>{damages.Grey}</Number>
          </Column>
          <Column>
            <HouseIcon icon="Un" />
            <Number>{damages.Un}</Number>
          </Column>
        </Damages>
      )}
      {exception && (
        <Exception>Bygning medtages ikke i tilstandsrapporten</Exception>
      )}
    </Container>
  );
}

/**
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const RowOrColumn = styled.div<{
  justify?: string;
  top?: number;
  bottom?: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(p) => (p.justify ? p.justify : "flex-start")};
  margin-top: ${(p) => (p.top ? `${p.top}px` : "0")};
  margin-bottom: ${(p) => (p.bottom ? `${p.bottom}px` : "0")};
  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

const Number = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;

const Exception = styled.div`
  font-size: 20px;
  color: red;
  transform: rotate(-15deg);
  text-align: center;
  position: absolute;
  bottom: 120px;
  left: 40px;
`;

const Damages = styled.div`
  position: absolute;
  bottom: 0;
  left: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 96px);
  cursor: pointer;
  @media (max-width: 1024px) {
    position: relative;
    padding-top: 8px;
  }
`;

const Key = styled(Text)<{ mobileEnd?: boolean }>`
  color: #757575;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 4px;
  margin-right: 4px;
  display: flex;
  @media (max-width: 420px) {
    justify-content: ${(p) => (p.mobileEnd ? "flex-end" : "flex-start")};
  }
`;

const Value = styled(Text)`
  margin-top: 4px;
  font-weight: bold;
  min-width: 38px;
`;

const ValueRight = styled(Value)`
  text-align: right;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #cccccc;
`;

const Container = styled.div`
  background-color: #f7f7f9;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
`;

const UsageYear = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default BuildingOverview;

import React from "react";
import styled from "styled-components";

import { Gjensidige } from "../../styles";

const { Column } = Gjensidige;

type Props = {
  style?: React.CSSProperties;
  cardHeight?: string;
  children?: React.ReactNode;
};

const CardContainer = React.forwardRef<any, Props>(
  ({ children, cardHeight = "569px", style }, ref) => {
    return (
      <Container
        ref={ref}
        style={{
          height: cardHeight,
          ...style,
        }}
      >
        {children}
      </Container>
    );
  },
);

const Container = styled(Column)`
  background-color: #f2f2f2;
  width: 100%;
  position: relative;
  @media (max-width: 1024px) {
    height: auto;
  }
`;

export default CardContainer;

import { ThemeType } from "../../types/Theme";
import defaultTheme from "./default";

// https://designnybolig.nykredit.dk/da/design/farver/
const nyboligcolors = {
  // primary
  tidepool: "#809EAD",
  stoneshore: "#122D3A",
  // accent
  sunnyday: "#FFFDB2",
  morningorange: "#FFB495",
  // neutral
  sand: "#EDE8E6",
  warmgray: "#C4C6C5",
  // actions
  nyboliggreen: "#00A06A",
  beige: "#f7f6f5",
};

const nyBoligTheme: ThemeType = {
  ...defaultTheme,
  headerCellColor: "#F2F2F2",
  headerCellBg: nyboligcolors.stoneshore,
  cellColor: "#FFFFFF",
  cellBg: nyboligcolors.tidepool,
  ringColor: nyboligcolors.nyboliggreen,
  placementLinkColor: nyboligcolors.nyboliggreen,
  anchorColor: nyboligcolors.nyboliggreen,
  headlineColor: nyboligcolors.stoneshore,
  damageAmount: nyboligcolors.stoneshore,
  cellValueColor: nyboligcolors.tidepool,
  cellKeyColor: nyboligcolors.stoneshore,
  textFontFamily: "'Franklin Gothic', sans-serif",
  headerFontFamily: "'Franklin Gothic', sans-serif",
  litraCardBg: nyboligcolors.sand,
  dmgCardBg: nyboligcolors.sand,
  caseOverviewCardBg: nyboligcolors.sand,
};

export default nyBoligTheme;

import { Box, Container, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

function CookiePolitikPage() {
  return (
    <Page>
      <Container>
        <Box sx={{ padding: { xs: "10px 20px 10px", md: "30px 0 70px" } }}>
          <h1>Cookiepolitik</h1>
          <Typography>
            Vi gør alt, hvad vi kan, for at besøgende på vores hjemmeside kan
            føle sig trygge. Her kan du læse mere om, hvordan vi indhenter,
            op-bevarer og behandler cookies.
          </Typography>
          <h3>Cookies, formål og relevans</h3>
          <Typography>
            Denne hjemmeside anvender cookies for at føre statistik over, hvor
            mange og hvordan vores brugere anvender vores side. Dette bl.a. for
            at skabe et overblik og for at kunne forbedre vores hjemmeside og
            således øge brugervenlighed og tilfredshed i forbindelse med et
            besøg.
            <br />
            <br /> Vi bruger også cookies til at sikre at vores hjemmeside
            fungerer som den skal, når du f.eks. kontakter os. Når vi placerer
            cookies, bliver du informeret om anvendelsen og formålet med at
            indsamle data via cookies.
          </Typography>
          <h5>Hvad er en cookie?</h5>
          <Typography>
            Cookies anvendes i dag af stort set alle websites. I mange tilfælde
            kan cookies være nødvendige for at levere en service på websitet. En
            cookie er en lille datafil, som hjemmesider gemmer på brugerens
            computer eller enhed for at kunne genkende enheden.
            <br />
            <br />
            Cookies kan blandt andet bruges til at udarbejde statistik over
            brugernes anvendelse af websitet. En cookie er en passiv fil og kan
            ikke indsamle oplysninger på brugerens computer eller enhed, sprede
            computervirus eller andre skadelige programmer.
            <br />
            <br />
            En cookie er en lille fil, der gemmes i din webbrowser (En
            webbrowser er et computerprogram, der bruges til at hente og vise
            hjemmesider, dette er eksempelvis Edge, Safari, Mozilla, Firefox,
            Chrome mv.). Cookies bruges til at kunne genkende din computer i
            tilfælde af tilbagevendende besøg. Der er ingen personlige
            oplysninger (som dit navn, adresse eller lignede) gemt i vores
            cookies.
            <br />
            <br />
            Vi placerer cookies på din enhed fra følgende samarbejdspartnere med
            følgende formål:
          </Typography>
          <h5>Google Analytics</h5>
          <Typography>
            Når du besøger nrgi.dk bliver dit besøg delt med Google for at vi
            kan lave statistik, forbedre vores hjemmeside og måle effektiviteten
            af vores markedsføring. Statistik data har intet udløb idet dette
            ikke er personhenførbart.
          </Typography>
          <h5>Google Optimize</h5>
          <Typography>
            Gør det muligt for os at teste varianter af vores sider og undersøge
            hvor effektive de er.
          </Typography>
          <h5>Google Tag Manager</h5>
          <Typography>
            Bruges i samarbejde med Google Analytics til at oprette
            handlingsbaseret måling. Det vil sige, at der bliver sendt en besked
            til Google analytics via GTM hvis et præindstillet kriterie bliver
            udført.
          </Typography>
          <h5>Google ReCaptcha</h5>
          <Typography>
            Baseret på vores interesse i forebyggelse af spam og misbrug bruger
            vi ReCaptcha-funktionen fra Google på vores websted. Denne funktion
            bruges primært til at afgøre, om et input foretages af en fysisk
            person eller uretmæssigt gennem en automatiseret behandling (Robot,
            m.m.). Tjenesten afsender automatisk informationer til Google
            (herunder IP-adresse, skærmstørrelse, dato, sprog, browser, browser
            plugins og antal klik og brugerens musebevægelser).
            <br />
            <br />
            Yderligere oplysninger om Google ReCaptcha og{" "}
            <a href="https://policies.google.com/privacy">
              Googles privatlivspolitik
            </a>{" "}
            findes på Googles Privatlivspolitik og{" "}
            <a href="https://policies.google.com/terms?hl=da">
              Googles Servicevilkår
            </a>
            .
            <br />
            <br />
            Hvis du giver os dit samtykke, placerer vi cookies fra følgende
            samarbejdspartnere:
          </Typography>
          <h5>DoubleClick by Google</h5>
          <Typography>
            Samtykker du til vores brug af cookies deler vi også oplysninger om
            din brug af vores website med Google. Google kan kombinere disse
            data med andre oplysninger, du har givet dem, eller som de har
            indsamlet fra din brug af deres tjenester. Dette sker med henblik på
            at kunne lave relevant markedsføring og for at kunne målrette
            annoncer til dig.
            <br />
            <br />
            Før vi placerer cookies på din enhed til brug for markedsføring,
            beder vi om dit samtykke. Nødvendige cookies til sikring af
            statistik, funktionalitet og indstillinger kan dog anvendes dog uden
            dit samtykke.
            <br />
            <br />
            Cookies sletter sig selv efter et vist antal måneder (kan variere),
            men de fornyes efter hvert besøg.
          </Typography>
          <h5>Sådan undgår du cookies</h5>
          <Typography>
            Hvis du ikke ønsker at modtage cookies kan du blokere dem. Læs mere
            om dette i denne{" "}
            <a href="http://minecookies.org/cookiehandtering">
              vejledning til hvordan du blokerer cookies
            </a>
            .{" "}
          </Typography>
          <h5>Sådan får du fintmasket kontrol over cookies</h5>
          <Typography>
            Hvis du ønsker over hvilke cookies der bliver placeret på din enhed
            anbefaler vi værktøjet ”
            <a href="https://www.ghostery.com/">Ghostery</a>”. Vi er på ingen
            måde affilieret med Ghostery eller yder support på brugen heraf. Du
            kan også opsætte din iOS enhed til at blokere visse cookies –{" "}
            <a href="https://support.apple.com/da-dk/HT201265">
              læs mere hos Apple
            </a>
            .
          </Typography>
          <script
            id="CookieDeclaration"
            src="https://consent.cookiebot.com/7e4008b2-a01b-41c4-b94d-294711e56c89/cd.js"
            type="text/javascript"
            async
          />
        </Box>
      </Container>
    </Page>
  );
}

const Page = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100%;
`;
export default CookiePolitikPage;

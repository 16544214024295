import { Box, CircularProgress, Grid } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import ElMistakeReport from "../../../components/ElMistakeReport";
import initGA from "../../../helpers/ga";
import { useStoreActions, useStoreState } from "../../../store/rootStore";
import Container from "../../../styles/Container";
import { Header, Text } from "../../../styles/Gjensidige";
import Headline from "../../../styles/Gjensidige/Headline";
import VimeoIframe from "../../../styles/Video";

function ElrapportPage() {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error("No ID was found!");
  }

  const ref = useRef(null);
  const { report, loaded } = useStoreState((state) => state.Report);
  const { getReport } = useStoreActions((actions) => actions.Report);

  useEffect(() => {
    if (!report) {
      getReport(id);
    }
  }, [report]);

  useEffect(() => {
    if (loaded) initGA(report);
  }, [report, loaded]);

  if (!loaded) {
    return <CircularProgress />;
  }

  if (!report) {
    return <p>Report not found {id}</p>;
  }
  return (
    <Page ref={ref}>
      <Container>
        {!loaded && <CircularProgress />}
        {loaded && (
          <>
            <Header
              address={`${report.Estate.Address}, ${report.Estate.ZipCode} ${report.Estate.City}`}
            />
            <MiddleCon>
              <Main className="main">
                <Headline h4 bottom={10} top={50}>
                  Om elrapporten
                </Headline>
                <Box />
                <Grid container>
                  <Grid item md={8} style={{ flex: 1, marginBottom: 20 }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <VimeoIframe
                        videoid="658535293"
                        width={Math.min(612, window.innerWidth / 2)}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} style={{ padding: "0 40px" }}>
                    <Text style={{ lineHeight: "21px" }}>
                      En elrapport minder om en tilstandsrapport og bruger en
                      lignende vurderingsmetode, men retter sig mere specifikt
                      mod husets synlige elinstallationer.
                      <br />
                      <br />
                      I gennemgangen bliver der lavet stikprøver på
                      stikkontakter og lampeudtag, og det bliver undersøgt, om
                      der er fejl eller deciderede ulovligheder i husets
                      elinstallationer.
                      <br />
                      <br />
                      Ligesom tilstandsrapporten er elrapporten desuden
                      obligatorisk for, at der kan tegnes en
                      ejerskifteforsikring.
                    </Text>
                  </Grid>
                </Grid>

                <ElMistakeReport />
                <Text style={{ padding: "20px 0" }}>
                  På sikkerhedsstyrelsens hjemmeside kan du læse mere om hvilke
                  el-opgaver, du selv må udføre og hvilke, du skal overlade til
                  en elektriker.{" "}
                  <a
                    target="_blank"
                    href="https://www.sik.dk/privat/goer-det-sikkert/el/goer-det-selv/hvilket-elarbejde-maa-du-lave-uden-autorisation"
                    rel="noreferrer"
                  >
                    Læs mere her.
                  </a>
                </Text>
              </Main>
            </MiddleCon>
          </>
        )}
      </Container>
    </Page>
  );
}

const Page = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100%;
`;

const MiddleCon = styled.div`
  width: 1024px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    margin-top: 0;
    padding-top: 2ch;
    margin-bottom: 0;
  }

  > * + * {
    padding-top: 1.5rem;
  }
`;
export default ElrapportPage;

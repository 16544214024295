import React from "react";
import styled from "styled-components";

import Headline from "./Headline";
import Text from "./Text";

/**
 * @returns {JSX.Element}
 * @constructor
 */
function Reservations() {
  return (
    <Container>
      <Headline h4>Forbehold</Headline>
      <Text>
        Den visuelle tilstandsrapport kan alene betragtes som et hjælpeværktøj
        til illustration af skader. Det er den indberettede tilstandsrapport,
        der er det juridiske dokument som danner udgangspunkt for
        ejendomshandlen. Den visuelle tilstandsrapport er ikke omfattet af
        huseftersynsordningens regler og kan således ikke erstatte
        tilstandsrapporten.
      </Text>
    </Container>
  );
}

const Container = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 8px;
  margin-bottom: 24px;
`;

export default Reservations;

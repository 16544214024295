import { Box, Grid } from "@material-ui/core";
import React from "react";

import "swiper/css"; // core Swiper
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Headline, Text } from "../../styles/Gjensidige"; // Pagination module
import ElErrorCodeCard from "./ElErrorCodeCard";
import ElMistakeCard from "./ElMistakeCard";
import { errorType, mistakes } from "./ElMistakeList";

function ElMistakeReport() {
  return (
    <div>
      <div>
        <Headline h4>
          Beskrivelse af de hyppigste fejl i en elinstallationsrapport (ikke den
          konkrete bolig)
        </Headline>
        <Text>
          Herunder kan du se en beskrivelse af nogle af de fejl, som du kan
          finde i en elinstallationsrapport. Fejlene og beskrivelserne er alle
          eksempler og hører altså ikke til en bestemt rapport eller bolig.
          <br />
          <br />
          Elinstallationsrapporterne bliver lavet af fagfolk, der beskriver
          fejlene præcist, i det fagsprog der er nødvendigt. Men som almindelig
          boligkøber eller -sælger kan det være svært at forstå, hvad fejlene
          betyder. Derfor har vi udarbejdet denne guide, der kan hjælpe dig til
          bedre at forstå den elrapport, du står med i hånden.
          <br />
          <br />
          Du kan se, hvad fejlene betyder, hvorfor de kan være farlige for dig
          eller din bolig, og hvad der skal til for at udbedre dem.
          <br />
          <br />
          Vi arbejder stadig på at udbygge guiden og gøre den bedre. Er der
          fejl, du savner en beskrivelse af, eller har du forslag eller input,
          så send dem gerne til{" "}
          <a href="mailto:feedback@nrgi.dk">feedback@nrgi.dk</a>.
        </Text>
      </div>
      <Grid
        container
        spacing={2}
        style={{
          marginTop: "30px",
          padding: "50px 0",
          width: "100%",
          margin: "0",
        }}
      >
        {mistakes.map((item, i) => (
          <Grid
            key={i}
            item
            xs={12}
            md={4}
            justifyContent="center"
            style={{ display: "flex" }}
          >
            <ElMistakeCard mistakeCase={item} />
          </Grid>
        ))}
      </Grid>
      <Box marginBottom="25px">
        <Headline h4>
          Det betyder symbolerne i elinstallationsrapporten
        </Headline>
        <Text>
          Fejl der registreres i elinstallationsrapporten, drejer sig altid om
          ulovlige forhold. Derudover vurderes det, hvilke konkrete konsekvenser
          fejlen kan få
        </Text>
      </Box>
      <Grid container style={{ background: "#f2f2f2" }}>
        {Object.values(errorType).map((item, i) => (
          <Grid key={i} item xs={12} md={6}>
            <ElErrorCodeCard
              description={item.description}
              title={item.title}
              image={item.image}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default ElMistakeReport;

import { Grid } from "@material-ui/core";
import React from "react";

import { Shared } from "../styles";
import {
  Building,
  Damage,
  Floor,
  DamageImage as DamageImageType,
} from "../types/Report";
import Card from "./Card";

const { Column, Headline } = Shared;

type Props = {
  floors: Floor[];
  damages: Damage[];
  elements: Building[];
  images: { [p: string]: DamageImageType[] };
  id: string;
  litra: string;
};

function Damages({ damages, litra, images, id, floors, elements }: Props) {
  let outside = [];
  let inside = [];
  outside = damages.filter((d) => {
    if (d.BuildingLitra === litra) {
      if (d.SelectedElements) {
        if (d.SelectedElements.length > 0) {
          return true;
        }
      }
    }
    return false;
  });
  inside = damages.filter((d) => {
    if (d.BuildingLitra === litra) {
      if (d.SelectedRooms) {
        if (d.SelectedRooms.length > 0) {
          return true;
        }
      }
    }
    return false;
  });
  return (
    <Column>
      {outside.length > 0 && (
        <>
          <Headline side={24} bottom={16} h3>
            Udvendig
          </Headline>
          <Grid container spacing={2}>
            {outside.map((o) => (
              <Grid key={o.LocationNumber} item xs={12} md={4}>
                <Card
                  floors={floors}
                  elements={elements}
                  damage={o}
                  id={id}
                  images={images[o.LocationNumber]}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {inside.length > 0 && (
        <>
          <Headline top={16} side={24} bottom={16} h3>
            Indvendig
          </Headline>
          <Grid container spacing={2}>
            {inside.map((o) => (
              <Grid key={o.LocationNumber} item xs={12} md={4}>
                <Card
                  floors={floors}
                  elements={elements}
                  id={id}
                  images={images[o.LocationNumber]}
                  damage={o}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Column>
  );
}

export default Damages;

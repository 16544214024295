import styled from "styled-components";

/**
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(p) => p.theme.containerBg};
`;
export default Container;

import React, { CSSProperties } from "react";
import styled from "styled-components";

// @ts-ignore
import {
  AvailableCompanies,
  getCompaniesForReport,
} from "../helpers/companiesForReport";
// @ts-ignore
import images from "./LogoImages";

class LogoMapper {
  companies: AvailableCompanies;

  constructor(companies: AvailableCompanies) {
    this.companies = companies;
  }

  getLogoNames() {
    const logoList: Array<keyof typeof images> = [];
    if (this.companies.isBotjek()) {
      logoList.push("Botjek");
    }
    if (this.companies.isEbas()) {
      logoList.push("Ebas");
    }
    if (this.companies.isFactum2()) {
      logoList.push("Factum2");
    }
    if (this.companies.isObh()) {
      logoList.push("Obh");
    }
    if (this.companies.isEdc()) {
      logoList.push("Edc");
    }
    if (this.companies.isEstate()) {
      logoList.push("Estate");
    }
    if (this.companies.isNybolig()) {
      logoList.push("Nybolig");
    }
    if (this.companies.isGjensidige()) {
      logoList.push("Gjensidige");
    }
    if (this.companies.isJesperNielsen()) {
      logoList.push("JesperNielsen");
    }
    if (this.companies.isDomutech()) {
      logoList.push("Domutech");
    }
    return logoList;
  }
}

/**
 * @param RequestorName
 * @param company
 * @param InspectionDate
 * @param ignoreList
 * @returns {JSX.Element}
 * @constructor
 */
function Footer({
  RequesterName,
  company,
  InspectionDate,
  ignoreList,
  style,
}: {
  RequesterName: string;
  company: string;
  InspectionDate: string;
  ignoreList?: any[];
  style?: CSSProperties;
}) {
  const companies = getCompaniesForReport({
    RequesterName,
    company,
    InspectionDate,
  });
  // console.log('ignorelist for pagE: ' + ignoreList)
  if (ignoreList === undefined) ignoreList = [];
  const logos = new LogoMapper(companies).getLogoNames().filter((l) => {
    // if (ignoreList.indexOf(l) === -1) return l;
    return ignoreList?.indexOf(l) === -1;
  });

  return (
    <Container style={style}>
      {logos.map((l) => (
        <div key={l}>
          {images[l].srcSet ? (
            <Img key={l} logo={l} src={images[l].srcSet} />
          ) : (
            <Img key={l} logo={l} src={images[l].src} />
          )}
        </div>
      ))}
    </Container>
  );
}

const Img = styled.img<{ logo: keyof typeof images }>`
  height: ${(p) => (p.logo === "Ebas" ? "50px" : "100px")};
  width: ${(p) => (p.logo === "Ebas" ? "auto" : "100px")};
  margin-left: 10px;
  margin-right: 10px;
  margin-top: ${(p) => (p.logo === "Ebas" ? "25px" : "0px")};
  ${(p) =>
    p.logo === "Ebas" &&
    "@media (max-width: 1024px) { height: 25px; width: 100px; }"}
`;

const FooterHeight = "119px";
const FooterTopPadding = "19px";

const Container = styled.footer`
  width: 100%;
  height: ${FooterHeight};
  background-color: ${(p) => p.theme.footerColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: ${FooterTopPadding};
`;

export const FooterTotalHeight = FooterHeight + FooterTopPadding;
export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

/**
 * @param children
 * @param to
 * @returns {JSX.Element}
 * @constructor
 */
function A({ children, to }: { children: React.ReactNode; to: string }) {
  return <PageLink to={to}>{children}</PageLink>;
}

const PageLink = styled(Link)`
  color: ${(p) => p.theme.pageLinkColor};
`;

export default A;

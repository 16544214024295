class Requester {
  requester: string = "";

  constructor(requester?: string) {
    this.requester = requester ? requester.toLowerCase() : "";
  }

  isEdc() {
    return this.requester.substring(0, 3) === "edc";
  }

  isEstate() {
    return this.requester.substring(0, 4) === "esta";
  }

  isNybolig() {
    return this.requester.substring(0, 4) === "nybo";
  }

  isJesperNielsen() {
      return (
          this.requester.substring(0, 4) === "jesp" ||
          this.requester.endsWith("jesper nielsen") ||
          this.requester.toLowerCase().split(" ").join("").includes('ejendomsmæglerfirmaet&living')
    );
  }
}

export default Requester;

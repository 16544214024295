// @ts-nocheck

import React from "react";

import Botjek from "../images/footer/botjek.png";
import Domutech from "../images/footer/domutech.jpg";
import Edc from "../images/footer/EDC.jpeg";
import Estate from "../images/footer/estate@100px.jpg";
import EstateLarge from "../images/footer/estate@270px.png";
import Factum2 from "../images/footer/factum2.jpg";
import Gjensidige from "../images/footer/Gjensidige.png";
import JesperNielsen from "../images/footer/jespernielsen.png";
import NrgiLogo from "../images/footer/nrgi_logo.svg";
import Nybolig from "../images/footer/Nybolig.jpg";
import Obh from "../images/footer/obh.png";

type ImageSource = React.ImgHTMLAttributes<any>["src"];
type ImageSourceSet = React.ImgHTMLAttributes<any>["srcSet"];

export interface Logo {
  src: ImageSource;
  srcSet?: ImageSourceSet;
}

const images: { [key: string]: Logo } = {
  Botjek: { src: Botjek },
  Ebas: { src: NrgiLogo },
  Factum2: { src: Factum2 },
  Obh: { src: Obh },
  Edc: { src: Edc },
  Estate: { src: Estate, srcSet: `${EstateLarge}` },
  Nybolig: { src: Nybolig },
  Gjensidige: { src: Gjensidige },
  JesperNielsen: { src: JesperNielsen },
  Domutech: { src: Domutech },
};

export default images;

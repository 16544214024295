import React from "react";
import styled, { keyframes } from "styled-components";

/**
 * @param circle
 * @param width
 * @returns {JSX.Element}
 * @constructor
 */
function Circle({ circle, width }: { circle: string; width: number }) {
  const [left, top] = circle.split(",");
  const absTop = (Number(top) / 100) * width;
  const absLeft = (Number(left) / 100) * width;

  return (
    <Container top={absTop} left={absLeft}>
      <Ring1 />
      <Ring2 />
      <Ring3 />
    </Container>
  );
}

const pulse = keyframes`
  0% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.9, 0.9);
  }
`;

const Container = styled.div<{ left: number; top: number }>`
  width: 32px;
  height: 32px;
  z-index: 10;
  position: absolute;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
`;

const Ring = styled.div`
  background-color: transparent;
  border-radius: 50%;
  top: 2px;
  left: 1px;
  position: absolute;
  border: 2px solid #0046ad;
`;

const Ring1 = styled(Ring)`
  border-width: 3px;
  width: 9px;
  height: 9px;
`;

const Ring2 = styled(Ring)`
  width: 22px;
  height: 22px;
  top: -5px;
  left: -5px;
  animation: ${pulse} 3.5s infinite;
`;

const Ring3 = styled(Ring)`
  width: 32px;
  height: 32px;
  top: -10px;
  left: -10px;
  animation: ${pulse} 3.5s infinite;
`;

export default Circle;

import styled from "styled-components";

/**
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Text = styled.div<{
  size?: number;
  right?: boolean;
  pre?: boolean;
  bold?: boolean;
  top?: number;
  bottom?: number;
}>`
  font-family: ${(p) => p.theme.textFontFamily};
  color: ${(p) => p.theme.textColor};
  font-size: ${(p) => (p.size ? `${p.size}px` : `${p.theme.textFontSize}`)};
  text-align: ${(p) => (p.right ? "right" : "left")};
  white-space: ${(p) => (p.pre ? "pre-wrap" : "normal")};
  font-weight: ${(p) => (p.bold ? "bold" : "normal")};
  margin-top: ${(p) => (p.top ? p.top : 0)}px;
  margin-bottom: ${(p) => (p.bottom ? p.bottom : 0)}px;
`;

export default Text;

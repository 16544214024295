import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { CSSProperties, useState } from "react";

import { BrandFeatures } from "../../features";
import { forEachFeature } from "../../store/featureStore";
import { useStoreActions, useStoreState } from "../../store/rootStore";

function Column({
  left,
  right,
  style,
  ...props
}: {
  left: React.ReactNode;
  right: React.ReactNode;
  style: CSSProperties;
}) {
  return (
    <Grid container style={style} {...props}>
      <Grid container xs item>
        {left}
      </Grid>
      <Grid container xs item>
        {right}
      </Grid>
    </Grid>
  );
}

interface FeatureProps {
  key: string;
  feature: { isEnabled: boolean; value: any };
}

export function Feature({ key, feature }: FeatureProps) {
  const { enableFeatures } = useStoreActions((state) => state.Features);
  return (
    <div style={{ marginLeft: "2ch", marginRight: "2ch" }}>
      <label htmlFor={`${key}_isEnabled`}>{key}</label>
      <Checkbox
        id={`${key}_isEnabled`}
        checked={feature.isEnabled}
        onClick={() => {
          enableFeatures({ key, isEnabled: !feature.isEnabled });
        }}
      />
    </div>
  );
}

type ToolbarProps = {
  children?: React.ReactNode;
};

export function FeatureToolbar({ children }: ToolbarProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const featureState = useStoreState((state) => state.Features);

  const { setFeatureList } = useStoreActions((state) => state.Features);

  if (!featureState.isDebug && !!children) {
    return children as JSX.Element;
  }

  const features: { key: string; feature: any }[] = [];

  forEachFeature(({ key }) => {
    features.push({
      key,
      // @ts-ignore
      feature: featureState[key],
    });
  });

  const buttonSize = {
    width: "120px",
    height: "50px",
  } as CSSProperties;
  const windowSize = {
    width: `${isOpen ? "50ch" : `calc(${buttonSize.height})`}`,
    height: `${isOpen ? "fit-content" : `calc(${buttonSize.width} + 1ch)`}`,
  } as CSSProperties;

  const button = (
    <Button onClick={() => setIsOpen(!isOpen)}>Feature toolbar</Button>
  );

  if (!isOpen) {
    return (
      <>
        <div
          style={{
            transform: `rotate(-90deg)`,
            position: "fixed",
            transformOrigin: "right bottom",
            top: 0,
            right: 0,
            zIndex: 9999999,
            opacity: "0.85",
            backgroundColor: "#fff",
            borderColor: "#111",
            borderStyle: "solid solid solid solid",
            borderWidth: "1px 1px 1px 1px",
            marginTop: "2ch",
            marginRight: "2ch",
            borderBottom: "0",
          }}
        >
          {button}
        </div>
        {children}
      </>
    );
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          transformOrigin: "right bottom",
          top: 0,
          right: 0,
          zIndex: 9999999,
          opacity: "0.85",
          backgroundColor: "#fff",
          borderColor: "#111",
          borderStyle: "solid solid solid solid",
          borderWidth: "1px 1px 1px 1px",
          marginTop: "2ch",
          marginRight: "2ch",
          borderBottom: "0",
        }}
      >
        <Column
          style={{ ...windowSize }}
          left={
            <div
              style={{
                transform: `rotate(-90deg) translateY(30%)`,
              }}
            >
              {button}
            </div>
          }
          right={
            isOpen ? (
              <div>
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="FeatureSetSelectLabel">
                      Feature set
                    </InputLabel>
                    <Select
                      labelId="FeatureSetSelectLabel"
                      id="FeatureSetSelect"
                      label="Feature set"
                      onChange={(event) => {
                        setFeatureList(event.target.value as string);
                      }}
                    >
                      {Object.keys(BrandFeatures).map((key) => (
                        <MenuItem value={key}>{key}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {features.map(Feature)}
                </div>
              </div>
            ) : undefined
          }
        />
      </div>
      {children}
    </>
  );
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Image from "./Image";

export function Overview({
  children,
  photo,
  id,
  fadein = false,
  litraPhotos,
}: {
  children: React.ReactNode;
  photo: string;
  id: string;
  fadein?: boolean;
  litraPhotos?: any;
}) {
  const [fade, setFade] = useState(!fadein);
  useEffect(() => {
    if (fadein) setFade(true);
  }, []);
  let src = photo;
  if (src === "" && litraPhotos.A) {
    src = litraPhotos.A;
  }
  return (
    <Container top={fadein}>
      <Left>{children}</Left>
      <Right fade={fade}>
        <Image
          building
          filter="saturate(1.4) contrast(1.1)"
          id={id}
          src={src}
        />
      </Right>
    </Container>
  );
}

export const Container = styled.div<{ top: boolean }>`
  height: 475px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-top: ${(p) => (p.top ? "0" : "24px")};
  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
`;

export const Left = styled.div`
  width: 475px;
  min-width: 475px;
  height: 100%;
  @media (max-width: 1024px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const Right = styled.div<{ fade: boolean }>`
  flex-grow: 1;
  height: 100%;
  transition: transform 1s, opacity 0.5s;
  transform: translateX(${(p) => (p.fade ? "0px" : "475px")});
  opacity: ${(p) => (p.fade ? "1" : "0.5")};
`;

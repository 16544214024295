import { CircularProgress } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import initGA from "../../../helpers/ga";
import { useStoreActions, useStoreState } from "../../../store/rootStore";
import Container from "../../../styles/Container";
import { Header, Text } from "../../../styles/Gjensidige";
import VimeoIframe from "../../../styles/Video";

function EjerskiftePage() {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error("No ID was found!");
  }
  const ref = useRef(null);

  const { report, loaded } = useStoreState((state) => state.Report);
  const { getReport } = useStoreActions((actions) => actions.Report);

  useEffect(() => {
    if (!report) {
      getReport(id);
    }
  }, [report]);

  useEffect(() => {
    if (loaded) {
      initGA(report);
    }
  }, [report, loaded]);

  if (!report) {
    return <p>Report not found</p>;
  }

  return (
    <Page ref={ref}>
      <Container>
        {!loaded && <CircularProgress />}
        {loaded && (
          <>
            <Header
              address={`${report.Estate.Address}, ${report.Estate.ZipCode} ${report.Estate.City}`}
            />
            <MiddleCon>
              <Main className="main">
                <Text>
                  En god ejerskifteforsikring er en vigtig del af en
                  bolighandel. Ejerskifteforsikringen dækker skjulte skader, der
                  ikke står i tilstands- og el-installationsrapporten, og som
                  allerede eksisterede, da du overtog boligen.
                </Text>
                <br />
                <Text>
                  Se filmen og få rådgivning om, hvad en ejerskifteforsikring
                  indeholder, og hvilke skader som typisk vil være dækket.
                </Text>
                <br />
                <VimeoIframe
                  videoid="703649854"
                  width={Math.min(1024, window.innerWidth)}
                />
                <br />
                <Text>
                  Vil du vide mere om ejerskifteforsikring i Gjensidige, så klik
                  på knappen herunder.
                </Text>
                <br />
              </Main>
            </MiddleCon>
            <LinkButton
              href="https://www.gjensidige.dk/ejerskifteforsikring"
              target="_blank"
              rel="noreferrer"
            >
              Læs mere hos Gjensidige
            </LinkButton>
          </>
        )}
      </Container>
    </Page>
  );
}

const Page = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100%;
`;

const LinkButton = styled.a`
  background-color: #7c55ff;
  color: white;
  text-decoration: none;
  font-size: 0.875rem;
  padding: 10px 60px;
  border-radius: 31px;
  margin: 30px 0px;
  cursor: pointer;
  line-height: 1.75;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-width: 0;
  &:hover {
    color: white;
    filter: brightness(95%);
    box-shadow: 0px 2px 2px #888888;
  }
`;

const MiddleCon = styled.div`
  width: 1024px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    margin-top: 0;
    padding-top: 2ch;
    margin-bottom: 0;
  }

  > * + * {
    padding-top: 1.5rem;
  }
`;

export default EjerskiftePage;

import { Grid } from "@material-ui/core";
import React from "react";

import { dictionaryTextDecorator } from "../DictionaryTextDecorator";
import VimeoIframe from "../Video";
import Headline from "./Headline";
import Text from "./Text";

function Stack({ children, ...props }: any) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      style={{ height: "100%" }}
      {...props}
    >
      {React.Children.toArray(children).map((item, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={index} item>
            {item}
          </Grid>
        );
      })}
    </Grid>
  );
}

function Intro() {
  // const [fade, setFade] = useState(true);
  // useEffect(() => {}, []);
  return (
    <div>
      <Stack
        style={{
          height: "fit-content",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <div>
          <Headline h4 bottom={5}>
            Et digitalt og visuelt tillæg
          </Headline>
          <Text>
            Som supplement til den grundige beskrivelse af husets tilstand i den
            originale tilstandsrapport får du også en visuel tilstandsrapport
            fra os. Så kan du med egne øjne se den enkelte skade, uanset hvor du
            befinder dig.
          </Text>
          <br />
          <Text>
            Billeder siger som bekendt mere end tusinde ord og er en kæmpe
            fordel, når skaden skal beskrives over for andre. Det kan fx være en
            håndværker, der skal give tilbud på at udbedre skaden.
          </Text>
        </div>
        <Stack alignItems="center">
          <Headline h4 bottom={5} style={{ marginTop: 25 }}>
            Sådan forstår du tilstandsrapporten
          </Headline>
          <VimeoIframe
            videoid="658536815"
            width={Math.min(512, window.innerWidth - 24)}
          />
        </Stack>
        <div style={{ marginTop: 10 }}>
          <Text>
            Den visuelle tilstandsrapport er et digitalt tillæg til ejendommens
            tilstandsrapport. Tilstandsrapporten danner grundlag for
            ejerskifteforsikringen. Læs mere her om hvilke skader en
            ejerskifteforsikring typisk{" "}
            <a
              href="https://www.gjensidige.dk/ejerskifteforsikring/vaerd-at-vide"
              target="_blank"
              rel="noreferrer noopener"
            >
              dækker
            </a>
            .
          </Text>
          <br />
          <DictionaryText>
            Klik på fagtermer med markering og få en ordforklaring.
          </DictionaryText>
        </div>
      </Stack>
    </div>
  );
}

const IntroDict = {
  Fagtermer:
    "Hvis du klikker på de markerede fagtermer, får du en forklaring af hvad de dækker over. Eksempelvis for 'Gavl': Endemuren på en bygning (typisk den korteste ydervæg). Udtrykket anvendes ofte alene om den trekant der dannes op mod taget, gavltrekanten.",
};

const DictionaryText = dictionaryTextDecorator(Text, IntroDict);

export default Intro;

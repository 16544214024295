import { KeyboardArrowRightRounded } from "@material-ui/icons";
import { forEach } from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";

import { calcPan } from "../helpers/calc";
import { generic } from "../styles";
import { DamageImage as DamageImageType } from "../types/Report";

const { Image, Circle } = generic;

type Props = {
  images: DamageImageType[];
  id: string;
  width: number;
  small?: boolean;
  selected?: string;
  resetSelected?: () => void;
};

function DamageImage({
  images,
  id,
  width,
  small,
  selected,
  resetSelected,
}: Props) {
  const [stacked, setStacked] = useState<DamageImageType[]>(images);
  const [update, setUpdate] = useState(1);

  useEffect(() => {
    const sortStacked: DamageImageType[] = [];
    let z = 9999;
    forEach(stacked, (s) => {
      if (s.circle) {
        sortStacked.unshift({ ...s, hide: false, z: 10000 });
      } else {
        sortStacked.push({ ...s, hide: false, z });
      }
      z -= 1;
    });
    setStacked(sortStacked);
    setUpdate(Math.random());
  }, []);

  useEffect(() => {
    if (selected) {
      const sortStacked: DamageImageType[] = [];
      let z = 9999;
      forEach(stacked, (s) => {
        if (s.fileId === selected) {
          sortStacked.unshift({ ...s, hide: false, z: 10000 });
        } else {
          sortStacked.push({ ...s, hide: false, z });
        }
        z -= 1;
      });
      setStacked(sortStacked);
      setUpdate(Math.random());
      if (resetSelected) {
        resetSelected();
      }
    }
  }, [selected]);

  if (!images) return null;
  if (images.length === 0) return null;

  const clickArrow = (i: DamageImageType, e: any) => {
    e.stopPropagation();
    const index = stacked.findIndex((x) => x.fileId === i.fileId);
    let newStacked = [...stacked];
    newStacked[index].hide = true;
    setStacked(newStacked);
    setUpdate(Math.random());
    setTimeout(() => {
      newStacked = [...stacked];
      newStacked[index].hide = false;
      // @ts-ignore
      const minValue = Math.min(...stacked.map((o) => o.z), 9999);
      newStacked[index].z = minValue - 1;
      setStacked(newStacked);
      setUpdate(Math.random());
    }, 1000);
  };

  return (
    <Container data-update={update}>
      {stacked.map((i) => (
        <Fragment key={i.fileId}>
          <ImageCon z={i.z} hide={i.hide}>
            <Image
              rotate={i.rotate || 0}
              scale={i.zoom}
              x={calcPan("x", i.pan, width, i.zoom)}
              y={calcPan("y", i.pan, width, i.zoom)}
              src={i.fileId}
              id={id}
              small={small}
            />
            {stacked.length > 1 && (
              <ArrowCircle onClick={(e) => clickArrow(i, e)}>
                <Arrow />
              </ArrowCircle>
            )}
            {i.circle !== "" && <Circle width={width} circle={i.circle} />}
          </ImageCon>
        </Fragment>
      ))}
    </Container>
  );
}

const ImageCon = styled.div<{ hide?: boolean; z?: number }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform ${(p) => (p.hide ? "0.5s" : "0s")} ease-in;
  transform: translateX(${(p) => (p.hide ? "-150%" : "0")})
    scale(${(p) => (p.hide ? "0.9" : "1")});
  z-index: ${(p) => p.z};
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const Arrow = styled(KeyboardArrowRightRounded)`
  color: white;
  font-size: 40px !important;
`;

const ArrowCircle = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: 4px;
  top: calc(50% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

export default DamageImage;

import { Action, action, Thunk, thunk } from "easy-peasy";

import { backendVersion } from "../api";
import { VersionData } from "../types/Version";

interface HomeStoreModel {
  data: VersionData;
  setData: Action<HomeStoreModel, VersionData>;
  getData: Thunk<HomeStoreModel>;
}

const homeStore: HomeStoreModel = {
  data: {
    version: "",
  },
  setData: action((state, payload) => {
    state.data = payload;
  }),
  getData: thunk(async (actions) => {
    const d = await backendVersion();
    if (d.ok && d.data) {
      actions.setData(d.data);
    }
  }),
};

export default homeStore;

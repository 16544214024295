import {ApiResponse} from "apisauce";
import {Action, action, thunk, Thunk} from "easy-peasy";
import {
    dictionary,
    getBuildingExpert,
    getEnergyLabel,
    getFilelist, getRedirect,
    getReport,
} from "../api";
import {DamageImage, Files, Report} from "../types/Report";

interface ReportStoreModel {
    page: string;
    report: Report | null;
    files: Files | null;
    dict: { [key: string]: string } | null;
    dictOpenWord: string | null;
    loaded: boolean;
    openDamageTypes: boolean;
    selectedDamage: number;
    images: DamageImage[];
    setReport: Action<ReportStoreModel, Report>;
    setFiles: Action<ReportStoreModel, Files>;
    setDict: Action<ReportStoreModel, { [key: string]: string }>;
    setDictOpenWord: Action<ReportStoreModel, string | null>;
    setLoaded: Action<ReportStoreModel, boolean>;
    setDamageTypes: Action<ReportStoreModel, boolean>;
    setSelectedDamage: Action<ReportStoreModel, number>;
    getReport: Thunk<ReportStoreModel, string>;
}

function jsonResponseOk<T, R>(response: ApiResponse<T, R>): boolean {
    return (
        response.ok &&
        response.headers !== undefined &&
        (response.headers["content-type"]?.includes("application/json") ?? false)
    );
}

const reportStore: ReportStoreModel = {
    page: "Report",
    report: null,
    files: null,
    dict: null,
    dictOpenWord: null,
    loaded: false,
    openDamageTypes: false,
    selectedDamage: -1,
    images: [],
    setReport: action((state, payload) => {
        state.report = payload;
    }),
    setFiles: action((state, payload) => {
        state.files = payload;
    }),
    setDict: action((state, payload) => {
        state.dict = payload;
        // for gjensidige theme intro to dictionary
        state.dict.Fagtermer =
            "Hvis du klikker på de markerede fagtermer, får du en forklaring af hvad de dækker over. Eksempelvis for 'Gavl': Endemuren på en bygning (typisk den korteste ydervæg). Udtrykket anvendes ofte alene om den trekant der dannes op mod taget, gavltrekanten.";
    }),
    setDictOpenWord: action((state, payload) => {
        state.dictOpenWord = payload;
    }),
    setLoaded: action((state, payload) => {
        state.loaded = payload;
    }),
    setDamageTypes: action((state, payload) => {
        state.openDamageTypes = payload;
    }),
    setSelectedDamage: action((state, payload) => {
        state.selectedDamage = payload;

        if (state.files?.damages[payload]) {
            state.images = state.files.damages[payload];
        } else {
            state.images = [];
        }
    }),
    getReport: thunk(async (actions, payload) => {
        console.log("fetching redirects for: " + payload);
        debugger;
        const redirect = await getRedirect(payload);
        //  Redirect if such is found
        if (redirect !== undefined && redirect.data !== null && redirect.data !== undefined && redirect.data !== '') {
            console.log("Redirecting to new url, redirect recieved: " + redirect);
            window.location.replace('/' + redirect.data ?? window.location.pathname);
        }

        const report = await getReport(payload);
        const files = await getFilelist(payload);
        const dict = await dictionary();

        if (jsonResponseOk(report)) {
            if (!report.data) return;

            if (!report.data.Consultant) {
                const reportWithProcessId = report.data as Report & {
                    ProcessId: string;
                };
                if (reportWithProcessId.ProcessId) {
                    const buildingExpert = await getBuildingExpert(
                        reportWithProcessId.ProcessId,
                    );

                    if (
                        jsonResponseOk(buildingExpert) &&
                        report.data &&
                        buildingExpert.data
                    ) {
                        report.data.Consultant = {
                            Company: buildingExpert.data.Company,
                            Email: buildingExpert.data.Email,
                            Name: buildingExpert.data.Name,
                            Phone: buildingExpert.data.Phone,
                            Title: `Bygningssagkyndig i ${buildingExpert.data.Company}`,
                        };
                    }
                }
            }

            if (!report.data.EnergymarkSerial) {
                const energyMarkSerial = await getEnergyLabel(payload);

                if (jsonResponseOk(energyMarkSerial) && energyMarkSerial.data) {
                    report.data.EnergymarkSerial = energyMarkSerial.data;
                } else {
                    report.data.EnergymarkSerial = "0";
                }
            }

            actions.setReport(report.data);
        }

        if (files.ok && files.data) {
            actions.setFiles(files.data);
        }

        if (dict.ok && dict.data) actions.setDict(dict.data);

        if (report.ok && files.ok) {
            actions.setLoaded(true);
        }
    }),
};

export default reportStore;

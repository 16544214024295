import Calculator from "@ebas/klimaberegner";
import { useEffect } from "react";
import styled from "styled-components";

import { baseUrl } from "../../../api";
import initGA from "../../../helpers/ga";
import { useStoreState } from "../../../store/rootStore";
import { Gjensidige } from "../../../styles";
import { Header } from "../../../styles/Gjensidige";

const { Container } = Gjensidige;

function EnergiberegnerPage() {
  const { report, loaded } = useStoreState((state) => state.Report);

  useEffect(() => {
    if (report) initGA(report);
  }, [report]);

  if (!loaded) {
    return <p>LOADING...</p>;
  }

  if (!report) {
    return <p>Report not found</p>;
  }

  const energyMarkSerial =
    !!report.EnergymarkSerial && report.EnergymarkSerial !== "0"
      ? report.EnergymarkSerial
      : "0";
  console.log(report.EnergymarkSerial);

  return (
    <Page>
      <Container>
        <Header
          address={`${report?.Estate?.Address}, ${report?.Estate?.ZipCode} ${report?.Estate?.City}`}
        />
        <Main className="main">
          <Calculator
            baseUrl={`${baseUrl()}/api/energySystemsProxy/`.replaceAll(
              "//api",
              "/api",
            )}
            lookup={
              energyMarkSerial ? { serialIdentifier: energyMarkSerial } : "0"
            }
          />
        </Main>
      </Container>
    </Page>
  );
}

const Page = styled.div`
  height: 100%;
  width: 100%;
`;

const Main = styled.main`
  width: 100%;
  height: 100%;
  background-color: #b8e6e4;
`;

export default EnergiberegnerPage;

import React from "react";

import { Headline, Text } from "../../styles/Gjensidige";
import { ErrorType } from "../../types/ElMistake";
import CardContainer from "../Gjensidige/CardContainer";

function ElErrorCodeCard(errorDescription: ErrorType) {
  const { image, title, description } = errorDescription;
  return (
    <CardContainer cardHeight="auto">
      <div
        style={{
          display: "flex",
          flex: 0,
          justifyContent: "flex-start",
          flexDirection: "row",
          // background: "white",
        }}
      >
        <div style={{ textAlign: "center", padding: "15px" }}>
          <img width={50} src={image} alt="El mistake" />
        </div>
        <div style={{ padding: "15px" }}>
          <Headline h4>{title}</Headline>
          <Text style={{ padding: "5px 0px" }}>{description}</Text>
        </div>
      </div>
    </CardContainer>
  );
}

export default ElErrorCodeCard;

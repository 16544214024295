import { Report } from "../../types/Report";
import { disableUntil, FeatureListType, urlFeatureEnabled } from "../index";

const isEnabled = (report: Report): boolean =>
  disableUntil(report, {
    day: 1,
    month: 1,
    year: 2022,
  }) || urlFeatureEnabled("theme2022");

export default function NyboligFeatures(report: Report): FeatureListType {
  return {
    dictionary: {
      isEnabled: isEnabled(report),
    },
    buildingExpert: {
      isEnabled: isEnabled(report),
    },
    theme: {
      isEnabled: isEnabled(report),
      value: "nybolig",
    },
    logoHeader: {
      isEnabled: isEnabled(report),
      value: "Nybolig",
    },
    headerTagline: {
      isEnabled: isEnabled(report),
      value: "Til alle tider. Til alle hjem.",
    },
  } as FeatureListType;
}

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { formatDate } from "../helpers/calc";
import { useStoreState } from "../store/rootStore";
import { Building } from "../types/Report";
import Anchor from "./Anchor";
import BuildingsList from "./BuildingsList";
import Headline from "./Headline";
import Text from "./Text";

/**
 * @param date
 * @param buildings
 * @param buildingExpert
 * @returns {JSX.Element}
 * @constructor
 */
function CaseOverview({
  date,
  buildings,
}: {
  date: string;
  buildings: Building[];
}) {
  const [fade, setFade] = useState(false);
  useEffect(() => {
    setFade(true);
  }, []);

  const { buildingExpert } = useStoreState((state) => state.Features);

  let buildingExpertSegment;
  if (buildingExpert.isEnabled && !!buildingExpert.value) {
    const { Name, Title, Email, Phone } = buildingExpert.value;
    buildingExpertSegment = (
      <>
        <PaddedHeadline h4>Bygningssagkyndig</PaddedHeadline>
        {Name ? <Text bold>{Name}</Text> : undefined}
        {Title ? <Text>{Title}</Text> : undefined}
        {Phone ? <Text>+45 {Phone}</Text> : undefined}
        {Email ? (
          <Text>
            <Anchor href={`mailto:${Email}`}>{Email}</Anchor>
          </Text>
        ) : undefined}
      </>
    );
  }

  return (
    <Container fade={fade}>
      <Headline bottom={24} h2>
        Bygningsoversigt
      </Headline>
      <BuildingsList buildings={buildings} />

      <Bottom>
        {buildingExpertSegment}
        <PaddedText bold>
          Besigtigelse foretaget den {date && formatDate(date.substring(0, 10))}
        </PaddedText>
      </Bottom>
    </Container>
  );
}

const PaddedHeadline = styled(Headline)`
  padding-bottom: 1ch;
`;
const PaddedText = styled(Text)`
  padding-top: 1ch;
`;

const Container = styled.div<{ fade?: boolean }>`
  background-color: ${(p) => p.theme.caseOverviewCardBg};
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 1s, opacity 0.5s;
  transform: translateX(${(p) => (p.fade ? "0px" : "-475px")});
  opacity: ${(p) => (p.fade ? "1" : "0.5")};
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
  @media (max-width: 1024px) {
    position: relative;
    bottom: auto;
    left: auto;
    margin-top: 18px;
  }
`;

export default CaseOverview;

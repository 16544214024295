import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Building, BuildingExpert } from "../../types/Report";
import BuildingsList from "./BuildingsList";
import Headline from "./Headline";
import Text from "./Text";

/**
 * @param date
 * @param buildings
 * @param buildingExpert
 * @returns {JSX.Element}
 * @constructor
 */
function CaseOverview({
  date,
  buildings,
  buildingExpert,
}: {
  date: string;
  buildings: Building[];
  buildingExpert: BuildingExpert;
}) {
  const [fade, setFade] = useState(false);
  useEffect(() => {
    setFade(true);
  }, []);

  const { Name, Company, Email, Cell } = buildingExpert ?? {};

  return (
    <Container fade={fade}>
      <PaddedHeadline h2>Bygningsoversigt</PaddedHeadline>
      <BuildingsList buildings={buildings} />
      <Bottom>
        {!!Name || !!Company || !!Email ? (
          <>
            <PaddedHeadline h4>Bygningssagkyndig</PaddedHeadline>
            {Name ? <Text bold>{Name}</Text> : undefined}
            {Company ? <Text>Bygningssagkyndig i {Company}</Text> : undefined}
            {Cell ? <Text>+45 {Cell}</Text> : undefined}
            {Email ? (
              <Text>
                <a href={`mailto:${Email}`}>{Email}</a>
              </Text>
            ) : undefined}
          </>
        ) : undefined}
        <PaddedText>
          Dato for besigtigelse: {date && date.substring(0, 10)}
        </PaddedText>
      </Bottom>
    </Container>
  );
}

const PaddedText = styled(Text)`
  padding-top: 1ch;
`;

const PaddedHeadline = styled(Headline)`
  //padding-bottom: 1ch;
`;

const Container = styled.div<{ fade?: boolean }>`
  background-color: #f7f7f9;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 1s, opacity 0.5s;
  transform: translateX(${(p) => (p.fade ? "0px" : "-475px")});
  opacity: ${(p) => (p.fade ? "1" : "0.5")};
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
`;

const Bottom = styled.div`
  //position: absolute;
  //bottom: 24px;
  //left: 24px;
  //@media (max-width: 1024px) {
  //  position: relative;
  //  bottom: auto;
  //  left: auto;
  //  margin-top: 18px;
  //}
`;

export default CaseOverview;

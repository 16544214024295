import { useEffect } from "react";
import styled from "styled-components";

import ReactWelcome from "../../components/ReactWelcome";
import { useStoreActions, useStoreState } from "../../store/rootStore";
import Container from "../../styles/Container";

function SplashPage() {
  const homeState = useStoreState((state) => state.Home);
  const { getData } = useStoreActions((actions) => actions.Home);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Con>
      <ReactWelcome />
      <State>
        <Text>API version: {homeState.data.version}</Text>
      </State>
    </Con>
  );
}

export default SplashPage;

const Con = styled(Container)`
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;
`;

const Text = styled.div``;

const State = styled.div`
  background-color: #282c34;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

import { ThemeType } from "../../types/Theme";
import defaultTheme from "./default";

const estateColors = {
  darkBlue: "#002D5F",
  dustyBlue: "#8bafcc",
  beige: "#dbd3c8",
  gray: "#A5ACAF",
  pageLinkColor: "#61dafb",
  darkGray: "#51626F",
};

const estateTheme: ThemeType = {
  ...defaultTheme,
  headerCellColor: "#F2F2F2",
  headerCellBg: estateColors.darkGray,
  cellColor: "#F2F2F2",
  cellBg: estateColors.dustyBlue,
  textColor: estateColors.darkBlue, // '#000000',
  headlineColor: estateColors.darkGray,
  damageAmount: "#000000",
  cellValueColor: "#000000",
  cellKeyColor: "#000000",
  ringColor: estateColors.darkBlue,
  placementLinkColor: estateColors.dustyBlue,
  anchorColor: estateColors.dustyBlue,
  footerColor: "rgb(165, 172, 175)",
  textFontFamily: "'Frutiger', 'Calibri', sans-serif",
  headerFontFamily: "'Frutiger', 'Calibri', sans-serif",
  litraCardBg: estateColors.beige,
  dmgCardBg: estateColors.beige,
  caseOverviewCardBg: estateColors.beige,
};

export default estateTheme;

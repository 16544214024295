import styled from "styled-components";

/**
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const Column = styled.div<{
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => (p.top ? `${p.top}px` : "0")};
  margin-bottom: ${(p) => (p.bottom ? `${p.bottom}px` : "0")};
  margin-left: ${(p) => (p.left ? `${p.left}px` : "0")};
  margin-right: ${(p) => (p.right ? `${p.right}px` : "0")};
`;
/**
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const Row = styled.div<{
  justify?: string;
  top?: string;
  bottom?: number;
  left?: number;
  right?: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(p) => (p.justify ? p.justify : "flex-start")};
  margin-top: ${(p) => (p.top ? `${p.top}px` : "0")};
  margin-bottom: ${(p) => (p.bottom ? `${p.bottom}px` : "0")};
  margin-left: ${(p) => (p.left ? `${p.left}px` : "0")};
  margin-right: ${(p) => (p.right ? `${p.right}px` : "0")};
`;
/**
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const Half = styled.div<{
  top?: string;
  bottom?: string;
  row?: boolean;
}>`
  width: 50%;
  display: flex;
  flex-direction: ${(p) => (p.row ? "row" : "column")};
  margin-top: ${(p) => (p.top ? `${p.top}px` : "0")};
  margin-bottom: ${(p) => (p.bottom ? `${p.bottom}px` : "0")};
`;
/**
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const Spacing = styled.div<{ height?: number }>`
  width: 100%;
  height: ${(p) => (p.height ? `${p.height}px` : "16px")};
`;

export const Line = styled.div<{ top?: number; bottom?: number }>`
  height: 1px;
  width: 100%;
  background-color: #cccccc;
  margin-top: ${(p) => (p.top ? `${p.top}px` : "0")};
  margin-bottom: ${(p) => (p.bottom ? `${p.bottom}px` : "0")};
`;

import { parse, format, parseISO } from "date-fns";

import { DamageNumberType } from "../types/Damage";

export const damageNumbers = (damages: any): DamageNumberType => {
  const numbers: DamageNumberType = { Red: 0, Yellow: 0, Grey: 0, Un: 0 };
  damages.forEach((d: any) => {
    let places = 0;
    if (d.SelectedElements) {
      places += d.SelectedElements.length;
    }
    if (d.SelectedRooms) {
      places += d.SelectedRooms.length;
    }
    if (
      d.Grade === "K3" ||
      d.Grade.substring(0, 3).toUpperCase() === "RØD" ||
      d.Grade.substring(0, 3).toUpperCase() === "RED"
    ) {
      numbers.Red += places;
    }
    if (
      d.Grade === "K2" ||
      d.Grade.substring(0, 3).toUpperCase() === "GUL" ||
      d.Grade.substring(0, 3).toUpperCase() === "YEL"
    ) {
      numbers.Yellow += places;
    }
    if (
      d.Grade === "K1" ||
      d.Grade.substring(0, 3).toUpperCase() === "GRÅ" ||
      d.Grade.substring(0, 3).toUpperCase() === "GRE"
    ) {
      numbers.Grey += places;
    }
    if (
      d.Grade === "K0" ||
      d.Grade.substring(0, 3).toUpperCase() === "SOR" ||
      d.Grade.substring(0, 3).toUpperCase() === "BLA"
    ) {
      numbers.Un += places;
    }
    if (d.Grade === "UN") {
      numbers.Un += places;
    }
  });

  return numbers;
};

export const calcPan = (
  xy: "x" | "y",
  pan: string,
  width: number,
  zoom: string,
) => {
  if (pan) {
    const [x, y] = pan.split(",") as unknown as [number, number];

    if (xy === "x") {
      return ((x / 100) * width) / Number(zoom);
    }
    return ((y / 100) * width) / Number(zoom);
  }
  return 0;
};

const monthTranslations = {
  Jan: "januar",
  Feb: "februar",
  Mar: "marts",
  Apr: "april",
  May: "maj",
  Jun: "juni",
  Jul: "juli",
  Aug: "august",
  Sep: "september",
  Oct: "oktober",
  Nov: "november",
  Dec: "december",
};

function isValidDate(d: any): d is Date {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(d) && d instanceof Date;
}

export function parseDate(inspectionDate: string): Date | undefined {
  if (!inspectionDate || inspectionDate.length < 10) {
    return undefined;
  }
  const date = inspectionDate.substring(0, 10);
  const strategies = [
    parse(date, "dd-MM-yyyy", new Date()),
    parseISO(date),
    parse(date, "MM-dd-yyyy", new Date()),
  ];

  const validDates = strategies.filter(isValidDate);

  if (!validDates || validDates.length === 0) {
    return undefined;
  }

  return validDates[0];
}

export const formatDate = (date: string) => {
  if (!date) {
    return "";
  }
  let result = date;
  try {
    const parsed = parseDate(date);

    if (!parsed) return "";

    result = `${format(parsed, "d")}. ${
      // @ts-ignore
      monthTranslations[format(parsed, "MMM")]
    } ${format(parsed, "yyyy")}`;
  } catch (e) {
    result = date;
  }
  return result;
};

import React from "react";
import styled from "styled-components";

import { Building } from "../types/Report";

/**
 * @param buildings
 * @returns {JSX.Element}
 * @constructor
 */
function BuildingsList({ buildings }: { buildings: Building[] }) {
  return (
    <Container>
      <Row>
        <HeaderCell>Bygning</HeaderCell>
        <HeaderCell>Anvendelse</HeaderCell>
        <HeaderCell>Opført</HeaderCell>
      </Row>
        <TableRowContainer>
      {buildings.length > 0 && (
        <>
          {buildings.map((b) => (
            <Row key={b.Litra}>
                  <Cell bold>Litra {b.Litra}</Cell>
                  <Cell>{b.Name ?? b.Usage}</Cell>
              <Cell small>{b.ConstructionYear}</Cell>
            </Row>
          ))}
        </>
      )}
        </TableRowContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableRowContainer = styled.div`
  @media (min-width: 1024px) {
    max-height: 190px;
    overflow: hidden;
    overflow-y: auto;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 20px;
  margin-bottom: 3px;
`;

const Cell = styled.div<{ small?: boolean; bold?: boolean }>`
  width: ${(p) => (p.small ? "78px" : "156px")};
  color: ${(p) => p.theme.cellColor};
  font-size: 14px;
  font-weight: ${(p) => (p.bold ? "bold" : "normal")};
  background-color: ${(p) => p.theme.cellBg};
  padding-left: 8px;
  padding-top: 2px;
`;

const HeaderCell = styled(Cell)`
  background-color: ${(p) => p.theme.headerCellBg};
  text-transform: uppercase;
  color: ${(p) => p.theme.headerCellColor};
  font-weight: bold;
  font-size: 15px;
  padding-bottom: 2px;
  padding-top: 2px;
`;

export default BuildingsList;

import React from "react";
import styled, { keyframes } from "styled-components";

import logo from "../logo.svg";

function ReactWelcome() {
  return (
    <Header>
      <AppLogo src={logo} alt="logo" />
      <Paragraph>Visuel Tilstandsrapport</Paragraph>
    </Header>
  );
}

const logoSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Header = styled.header`
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const AppLogo = styled.img`
  height: 40vmin;
  pointer-events: none;
  animation: ${logoSpin} infinite 20s linear;
`;

const Paragraph = styled.p``;

export default ReactWelcome;

import { Grid } from "@material-ui/core";
import React from "react";
import styled, { useTheme } from "styled-components";

// @ts-ignore
import { useStoreState } from "../store/rootStore";
import Headline from "./Headline";
// @ts-ignore
import images from "./LogoImages";
import Text from "./Text";

/**
 * @param address
 * @param scroll
 * @returns {JSX.Element}
 * @constructor
 */
function Header({ address, scroll }: { address: string; scroll: boolean }) {
  const theme = useTheme();
  const { logoHeader, headerTagline } = useStoreState(
    (state) => state.Features,
  );

  return (
    <Container scroll={scroll}>
      <Grid container spacing={0}>
        {logoHeader.isEnabled && (
          <Grid item xs={12} md={1}>
            <picture>
              {/* @ts-ignore */}
              <Img scroll={scroll} src={images[logoHeader.value].src} />
            </picture>
          </Grid>
        )}

        {/* @ts-ignore */}
        <Grid item xs={12} md={theme.company === "" ? 7 : 6}>
          {headerTagline.isEnabled && !scroll && (
            <Text size={16} top={4} bottom={8}>
              {headerTagline.value}
            </Text>
          )}
          <Headline
            top={scroll ? (headerTagline.isEnabled ? 18 : 12) : 0}
            h1={!scroll}
            h2={scroll}
          >
            Visuel Tilstandsrapport
          </Headline>
        </Grid>
        <Grid item xs={12} md={5}>
          <AddressCon scroll={scroll}>
            <Text size={16} right>
              {address}
            </Text>
          </AddressCon>
        </Grid>
      </Grid>
      <Line scroll={scroll} />
    </Container>
  );
}

const Img = styled.img<{ scroll?: boolean }>`
  height: 70px;
  width: 70px;
  transition: transform 0.3s;
  transform: scale(${(p) => (p.scroll ? 0.5 : 1)});
`;

const Container = styled.div<{ scroll?: boolean }>`
  padding-top: 24px;
  position: fixed;
  width: 1024px;
  background-color: #ffffff;
  z-index: 133700;
  transition: transform 0.3s;
  transform: translateY(${(p) => (p.scroll ? "-24px" : "0")});
  @media (max-width: 1024px) {
    width: 100%;
    padding-top: 8px;
  }
`;

const AddressCon = styled.div<{ scroll?: boolean }>`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: ${(p) => (p.scroll ? "center" : p.theme.headerAdressAlign)};
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const Line = styled.div<{ scroll?: boolean }>`
  height: 1px;
  width: 100%;
  margin-top: ${(p) => (p.scroll && p.theme.headerText !== "" ? 0 : "16px")};
  background-color: #cccccc;
  @media (max-width: 1024px) {
    margin-top: 0px;
  }
`;

export default Header;

const sortLitra = (a: any, b: any) => {
  if (a.Litra) {
    if (a.Litra > b.Litra) {
      return 1;
    }
    if (a.Litra < b.Litra) {
      return -1;
    }
  }
  if (a.Number) {
    return a.Number - b.Number;
  }
  return 0;
};

export default sortLitra;
